import { ShinhanConnectBottomSheet } from 'components/v2/shinhan/ShinhanConnectBottomSheet'
import LoadingSpinner from 'components/v2/common/LoadingSpinner'

import { useHXAP } from './useHXAP'

export function HigherXAppProtocol() {
  const { processing } = useHXAP()

  return (
    <>
      {processing ? (
        <LoadingSpinner />
      ) : (
        <>
          <ShinhanConnectBottomSheet />
        </>
      )}
    </>
  )
}
