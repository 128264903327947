import { HXAP } from 'lib/bridge'

import { HXAPPromiseMap } from './HXAPPromiseMap'
import { HXAPEvent, ReactNativeEvent } from './types'
import { HXAPSystemEventHandler } from 'lib/bridge/event/types'
import { getLoginState } from 'lib/utils/login/loginState'
import { mount } from 'lib/bridge/actions/mount'
import {
  HXAPEventMap,
  HXAPEventUnsubscribe,
  HXAPEventHandle,
} from '../event/types'

namespace internal {
  export class BridgeService {
    private _promiseMap: HXAPPromiseMap
    private _systemEventHandles: Record<
      string,
      Omit<HXAPSystemEventHandler, 'processing'>
    >
    private _eventHandles: Record<string, HXAPEventHandle[]>
    private _deferredEventQueue: HXAPEvent[]
    private _timer: any

    constructor() {
      this._promiseMap = new HXAPPromiseMap()
      this._systemEventHandles = {}
      this._eventHandles = {}
      this._deferredEventQueue = []
      this._timer = null
    }

    public get promiseMap(): HXAPPromiseMap {
      return this._promiseMap
    }

    public async register(
      ...handlers: HXAPSystemEventHandler[]
    ): Promise<void> {
      if (typeof window === 'undefined') return
      handlers.map(handler => {
        this._systemEventHandles[handler.eventName] = {
          ...handler,
        }
      })
      window.addEventListener('loginState', this.handleLoginState.bind(this))

      switch (HXAP.getPlatform()) {
        case 'ios':
          window.addEventListener('message', this.handleMessage.bind(this))
          break
        case 'android':
          document.addEventListener('message', this.handleMessage.bind(this))
          break
      }
      await mount()
    }

    public unregister(): void {
      switch (HXAP.getPlatform()) {
        case 'ios':
          window.removeEventListener('message', this.handleMessage.bind(this))
          break
        case 'android':
          document.removeEventListener('message', this.handleMessage.bind(this))
          break
      }
      window.removeEventListener('loginState', this.handleLoginState.bind(this))
    }

    public addListener<T extends keyof HXAPEventMap>(
      event: T,
      listener: HXAPEventMap[T]
    ): HXAPEventUnsubscribe {
      if (!this._eventHandles[event]) {
        this._eventHandles[event] = []
      }
      this._eventHandles[event].push(listener)

      return () => {
        this._eventHandles[event] = this._eventHandles[event].filter(
          handle => handle !== listener
        )
      }
    }

    public addDeferredEventQueue(event: HXAPEvent): void {
      console.log(
        'insert deferred event queue',
        event,
        this._deferredEventQueue.length
      )
      this._deferredEventQueue.push(event)
    }

    private dispatchSystemEvent(event: HXAPEvent): boolean {
      const handler = this._systemEventHandles[event.name]
      if (!handler) return false

      if (this._timer !== null) {
        clearTimeout(this._timer)
        this._timer = null
      }

      this._timer = setTimeout(() => {
        const loginState = getLoginState()
        if (handler.needLogin && !loginState) {
          this.addDeferredEventQueue(event)
        } else {
          const eventPayload = JSON.parse(event.payload ?? '{}')
          handler.handle(eventPayload)
        }
      }, 100)
      return true
    }

    private dispatchNativeEvent(event: HXAPEvent): boolean {
      const handler = this._eventHandles[event.name]
      if (!handler) return false

      const payload = JSON.parse(event.payload ?? '{}')
      handler.forEach(handle => {
        handle(payload)
      })
      return true
    }

    private handleMessage(event: ReactNativeEvent): void {
      const payload: HXAPEvent = JSON.parse(event.data) as HXAPEvent
      if (
        !payload ||
        this.dispatchSystemEvent(payload) ||
        this.dispatchNativeEvent(payload)
      ) {
        return
      }

      this.promiseMap.resolve(payload.id, payload)
    }

    private handleLoginState(
      event: CustomEvent<{ loginState: boolean }>
    ): void {
      if (!event.detail.loginState) return

      this._deferredEventQueue.forEach(hxapEvent =>
        this.dispatchSystemEvent(hxapEvent)
      )
      this._deferredEventQueue = []
    }
  }
}

export const BridgeService = new internal.BridgeService()
