import { callNative } from '../core/callNative'

/**
 * ## openURL
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 링크를 사용자 웹 브라우저로 엽니다.
 */
export async function openURL(url: string): Promise<void> {
  await callNative('openURL', { url })
}
