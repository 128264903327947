import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const now = new Date()

export type ChecklistDateStateType = {
  checklistDate: string
}

const initialState: ChecklistDateStateType = {
  checklistDate: new Date(now.getTime() - now.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0],
}

const checklistDateState = createSlice({
  name: 'checklistDateState',
  initialState,
  reducers: {
    setChecklistDate: (state, action: PayloadAction<string>) => {
      state.checklistDate = action.payload
    },
  },
})

export const { setChecklistDate } = checklistDateState.actions

export default checklistDateState.reducer
