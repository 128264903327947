import { callNative } from '../core/callNative'
import { OpenCameraArgs, NativeImage } from './types'

/**
 * ## openCamera
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * presigned url을 이용한 파일 업로드를 수행하는 카메라 화면을 엽니다.
 *
 * @returns
 * * id : 파일 id
 * * previewUrl : 미리보기 이미지 url
 */
export async function openCamera(
  payload: OpenCameraArgs
): Promise<NativeImage[] | null> {
  const data = await callNative('openCamera', payload, false)
  return data as NativeImage[]
}
