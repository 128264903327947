import axios from 'axios'
import { InvitationType } from './types'

export async function getInvitationReceiver(
  params: GetInvitationReceiverParams
) {
  const response = await axios.get<InvitationType>(
    '/v1/store/invitation/receiver',
    {
      baseURL: process.env.NEXT_PUBLIC_BASE_URL,
      params: params,
    }
  )

  return response.data
}

export type GetInvitationReceiverParams = {
  code: string
}
