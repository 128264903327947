import client from '../client'

export async function postJobSearchMode(params: PostJobSearchModeParams) {
  const response = await client.post(
    `/v1/user/job-search-mode?jobSearchMode=${params.jobSearchMode}`
  )

  return response.data
}

export type PostJobSearchModeParams = {
  jobSearchMode: 'EMPLOYEE' | 'EMPLOYER'
}
