import client from '../client'
import { InventoryType } from './types'

export async function getInventory({ inventory_id }: GetInventoryParams) {
  const response = await client.get<InventoryType>(
    `/v1/store/barcode/inventory/${inventory_id}`
  )

  return response.data
}

export type GetInventoryParams = {
  inventory_id: number
}
