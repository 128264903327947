import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CopyChecklistStateType = {
  fromStoreId: number
  toCategoryIds: string[]
  isBreak?: boolean
}

const initialState: CopyChecklistStateType = {
  fromStoreId: 0,
  toCategoryIds: [],
  isBreak: false,
}

export const copyChecklistStateSlice = createSlice({
  name: 'copyChecklist',
  initialState,
  reducers: {
    setFromStoreId: (state, action: PayloadAction<number>) => {
      state.fromStoreId = action.payload
    },
    setToCategoryIds: (state, action: PayloadAction<string[]>) => {
      state.toCategoryIds = action.payload
    },
    initCopyChecklistState: state => {
      Object.assign(state, initialState)
    },
    setCopyIsBreak: (state, action: PayloadAction<boolean>) => {
      state.isBreak = action.payload
    },
  },
})

export const {
  setFromStoreId,
  setToCategoryIds,
  initCopyChecklistState,
  setCopyIsBreak,
} = copyChecklistStateSlice.actions

export default copyChecklistStateSlice.reducer
