import { DEFAULT_BOTTOM_SHEET_STACK } from './value'
import React, { createContext, useRef, useState } from 'react'

type BottomSheetContextType = {
  sheetRef: React.RefObject<HTMLDivElement>

  bottomSheetStack: string[]
  pushBottomSheetStack: (value: string) => void
  popBottomSheetStack: () => void
  initBottomSheetStack: () => void

  closeDetect: boolean
  setCloseDetect: React.Dispatch<React.SetStateAction<boolean>>

  backToPosition: boolean

  positionY: number
  setPositionY: React.Dispatch<React.SetStateAction<number>>

  handleTouchStart: (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => void
  handleTouchMove: (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => void
  handleTouchEnd: (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => void
}

export const BottomSheetContext = createContext<BottomSheetContextType>({
  sheetRef: { current: null },

  bottomSheetStack: [DEFAULT_BOTTOM_SHEET_STACK],
  pushBottomSheetStack: () => {},
  popBottomSheetStack: () => {},
  initBottomSheetStack: () => {},

  closeDetect: false,
  setCloseDetect: () => {},

  backToPosition: false,

  positionY: 0,
  setPositionY: () => {},

  handleTouchStart: () => {},
  handleTouchMove: () => {},
  handleTouchEnd: () => {},
})

export default function BottomSheetProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const sheetRef = useRef<HTMLDivElement>(null)

  const [bottomSheetStack, setBottomSheetStack] = useState<string[]>([
    DEFAULT_BOTTOM_SHEET_STACK,
  ])

  const [closeDetect, setCloseDetect] = useState<boolean>(false)
  const [backToPosition, setBackToPosition] = useState<boolean>(false)

  const [dragY, setDragY] = useState<number>(0)
  const [positionY, setPositionY] = useState<number>(0)

  const pushBottomSheetStack = (value: string) => {
    bottomSheetStack.push(value)
    setBottomSheetStack([...bottomSheetStack])
  }

  const popBottomSheetStack = () => {
    bottomSheetStack.pop()
    setBottomSheetStack([...bottomSheetStack])
  }

  const initBottomSheetStack = () => {
    setBottomSheetStack([DEFAULT_BOTTOM_SHEET_STACK])
  }

  const handleTouchStart = (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => {
    setBackToPosition(false)

    const settingDragY = () => {
      const startDragY = e.changedTouches[0].clientY
      setDragY(startDragY)
    }

    const isTop = sheetRef.current?.scrollTop === 0

    if (executeAtTop) {
      if (isTop) settingDragY()
    } else {
      settingDragY()
    }
  }

  const handleTouchMove = (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => {
    const settingPositionY = () => {
      const positionY = e.changedTouches[0].clientY

      const newPositionY = positionY - dragY
      const isDragDown = newPositionY > 0
      if (isDragDown) setPositionY(newPositionY)
    }

    const isTop = sheetRef.current?.scrollTop === 0

    if (executeAtTop) {
      if (isTop) settingPositionY()
    } else {
      settingPositionY()
    }
  }

  const handleTouchEnd = (
    e: React.TouchEvent<HTMLDivElement>,
    executeAtTop?: boolean
  ) => {
    const actionOnTouchEnd = () => {
      const endDragY = e.changedTouches[0].clientY

      if (endDragY - dragY > ((sheetRef.current?.clientHeight ?? 0) + 48) / 2) {
        setCloseDetect(true)
      } else {
        setBackToPosition(true)
        setPositionY(0)
      }
    }

    const isTop = sheetRef.current?.scrollTop === 0

    if (executeAtTop) {
      if (isTop) actionOnTouchEnd()
    } else {
      actionOnTouchEnd()
    }
  }

  return (
    <BottomSheetContext.Provider
      value={{
        sheetRef,

        bottomSheetStack,
        pushBottomSheetStack,
        popBottomSheetStack,
        initBottomSheetStack,

        backToPosition,

        closeDetect,
        setCloseDetect,

        positionY,
        setPositionY,

        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
      }}
    >
      {children}
    </BottomSheetContext.Provider>
  )
}
