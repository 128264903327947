import { HXAPEvent } from './types'

export type HXAPPromise = (event: HXAPEvent) => void

export class HXAPPromiseMap {
  private _promiseMap: Record<string, HXAPPromise>

  constructor() {
    this._promiseMap = {}
  }

  public register(id: string, promise: HXAPPromise) {
    this._promiseMap[id] = promise
  }

  public unregister(id: string) {
    if (this._promiseMap) {
      delete this._promiseMap[id]
    }
  }

  public resolve(id: string, result: HXAPEvent) {
    const promise: HXAPPromise | undefined = this._promiseMap[id]
    promise?.(result)
  }
}
