import { callNative } from '../core/callNative'
import { NativeFile, OpenFileArgs } from './types'

/**
 * ## openFileDialog
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * presigned url을 이용한 파일 업로드를 수행하는 파일 선택 화면을 엽니다.
 *
 * @returns
 * * id : 파일 id
 * * name : 파일 이름
 */
export async function openFileDialog(
  payload: OpenFileArgs
): Promise<NativeFile[] | null> {
  const data = await callNative('openFileDialog', payload, false)
  return data as NativeFile[]
}
