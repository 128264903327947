import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type BackStateType = {
  overlayStack: string[]
  hasCustomBackHandler: boolean
  isTriggedBackHandler: boolean
  isBackEventBlock: boolean
  popedOverlayStack: string | undefined
}

const initialState: BackStateType = {
  overlayStack: [],
  hasCustomBackHandler: false,
  isTriggedBackHandler: false,
  isBackEventBlock: false,
  popedOverlayStack: undefined,
}

const backState = createSlice({
  name: 'backState',
  initialState,
  reducers: {
    pushOverlayStack: (state, action: PayloadAction<string>) => {
      state.overlayStack.push(action.payload)
      state.overlayStack = [...state.overlayStack]
    },
    popOverlayStack: state => {
      state.popedOverlayStack = state.overlayStack.pop()
      state.overlayStack = [...state.overlayStack]
    },
    initPopedOverlayStack: state => {
      state.popedOverlayStack = undefined
    },
    deleteOverlayStack: (state, action: PayloadAction<string>) => {
      state.overlayStack = state.overlayStack.filter(
        item => item !== action.payload
      )
      state.overlayStack = [...state.overlayStack]
    },
    setHasCustomBackHandler: (state, action: PayloadAction<boolean>) => {
      state.hasCustomBackHandler = action.payload
    },
    setIsTriggedBackHandler: (state, action: PayloadAction<boolean>) => {
      state.isTriggedBackHandler = action.payload
    },
    blockBackEvent: state => {
      state.isBackEventBlock = true
    },
    activeBackEvent: state => {
      state.isBackEventBlock = false
    },
  },
})

export const {
  pushOverlayStack,
  popOverlayStack,
  initPopedOverlayStack,
  deleteOverlayStack,
  setHasCustomBackHandler,
  setIsTriggedBackHandler,
  blockBackEvent,
  activeBackEvent,
} = backState.actions
export default backState.reducer
