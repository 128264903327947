import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnteredStoreStateType } from './enteredStoreState'

export type DeletedEnteredStoreType = {
  deletedStore: EnteredStoreStateType | undefined
}

const initialState: DeletedEnteredStoreType = {
  deletedStore: undefined,
}

const deletedEnteredStore = createSlice({
  name: 'tempPageData',
  initialState,
  reducers: {
    setDeletedStoreInfo: (
      state,
      action: PayloadAction<EnteredStoreStateType>
    ) => {
      state.deletedStore = action.payload
    },
  },
})

export const { setDeletedStoreInfo } = deletedEnteredStore.actions

export default deletedEnteredStore.reducer
