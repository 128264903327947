import { AxiosRequestConfig } from 'axios'

let accessToken: string = ''

export function setServerSideToken(token: string) {
  accessToken = token
}

export async function serverSideInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    }
  }
  return config
}
