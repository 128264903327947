import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AddressType = {
  address: string
  sidoName: string
  sigunguName: string
  dongName: string
  sigunguCode: string
  dongCode: string
  changeType: 'add' | 'edit'
}

export type ResumeAddressStateType = {
  firstAddress?: AddressType
  secondAddress?: AddressType
}

const initialState: ResumeAddressStateType = {
  firstAddress: undefined,
  secondAddress: undefined,
}

export const resumeAddressStateSlice = createSlice({
  name: 'resumeAddressState',
  initialState,
  reducers: {
    setResumeFirstAddress: (state, action: PayloadAction<AddressType>) => {
      state.firstAddress = action.payload
    },
    setResumeSecondAddress: (state, action: PayloadAction<AddressType>) => {
      state.secondAddress = action.payload
    },
    initResumeAddress: state => {
      state.firstAddress = undefined
      state.secondAddress = undefined
    },
  },
})

export const {
  setResumeFirstAddress,
  setResumeSecondAddress,
  initResumeAddress,
} = resumeAddressStateSlice.actions

export default resumeAddressStateSlice.reducer
