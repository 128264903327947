import { safeLocalStorage } from 'lib/utils/storage'

/**
 * ## virtualLoadData
 *
 * @description
 * 기기에 저장한 값을 불러옵니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 null
 */
export async function virtualLoadData<T = any>(key: string): Promise<T | null>

/**
 * ## virtualLoadData
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 기기에 저장한 값을 불러옵니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 defaultValue
 */
export async function virtualLoadData<T = any>(
  key: string,
  defaultValue?: T
): Promise<T>

export async function virtualLoadData<T = any>(
  key: string,
  defaultValue?: T
): Promise<T | null> {
  const data = safeLocalStorage.get(key)
  if (!!data) {
    return (JSON.parse(data) as T) ?? defaultValue ?? null
  }
  return null
}
