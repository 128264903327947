import { getAppVersion } from './getAppVersion'
import { getCodePushVersion } from './getCodePushVersion'

/**
 * ## getFullVersion
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 앱의 version과 codepush version을 모두 포함한 버전 값을 가져옵니다.
 *
 * @returns {string} 0.0.0 or 0.0.0p0
 */
export async function getFullVersion(): Promise<string> {
  const appVersion = await getAppVersion()
  const codePushVersion = await getCodePushVersion()

  let version = appVersion
  if (codePushVersion !== 0) {
    version += ` p${codePushVersion}`
  }
  return version
}
