import { callNative } from '../core/callNative'

/**
 * ## smsMessage
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 메시지 공유
 */
export async function smsMessage(text: string): Promise<void> {
  await callNative('smsMessage', {
    text,
  })
}
