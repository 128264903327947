import { configureStore, EnhancedStore, Store } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import reducer, { StateType } from './modules'

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

const setupStore = (): EnhancedStore => store

const makeStore = () => setupStore()

export const wrapper = createWrapper<Store<StateType>>(makeStore)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default wrapper
