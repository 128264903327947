import useMyStoreQuery, {
  createMyStoreKey,
} from 'hooks/query/store/useMyStoreQuery'
import { BottomSheet } from '../designSystem/BottomSheet'
import Text from 'components/v2/common/Text'
import { Router, useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import StoreCodeEditForm from './StoreInfoEditList/StoreCodeEditForm'
import useToast from 'hooks/useToast'
import { useQueryClient } from 'react-query'
import { getDateObjectFormat } from 'lib/utils/date/dateService'

const mainTabUrls = ['/todayChecklist', '/shelfLife', '/more']

// GS 예비 매장이고, 메인 탭 전환 시 / 해당 바텀시트가 나타나도록 설계됨
export default function GsStoreCodeGuideBottomSheet() {
  const queryClient = useQueryClient()

  const currentPath = useRef<string>()

  const toast = useToast()

  const { asPath } = useRouter()

  // 매장이 없는 알바생의 경우 계속 호출하면 낭비이기 때문에 retry 제한
  const { data: myStore } = useMyStoreQuery({}, { retry: 1 })

  // gs 예비 매장
  const isSpareGs = myStore?.store_code === 'V00001'

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleRouteChangeStart = () => {
      // route 이동 전 url
      currentPath.current = asPath
    }

    const handleRouteChangeComplete = () => {
      if (!isSpareGs) return

      const dayDiff =
        (new Date().getTime() - new Date(myStore.date_created).getTime()) /
        (1000 * 60 * 60 * 24)

      if (dayDiff < 14) return

      for (let i = 0; i < mainTabUrls.length; i++) {
        // main 페이지 이내에서 depth가 발생하고 다시 main 페이지로 돌아올 경우에는 BottomSheet가 나타나지 않도록 막는 조건문입니다
        // ex) 더보기 => 프로필 => 더보기 : 해당 경우에는 나타나지 않도록 처리
        if (
          currentPath.current?.includes(mainTabUrls[i]) &&
          asPath === mainTabUrls[i]
        ) {
          return
        }
      }

      if (mainTabUrls.includes(asPath)) {
        setTimeout(() => {
          setOpen(true)
        }, 100)
      }
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart)
    Router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart)
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [asPath, isSpareGs])

  return (
    <BottomSheet height='fitContent' open={open} onOpenChange={setOpen}>
      <BottomSheet.Content>
        <BottomSheet.Title>
          <Text variant='titleLarge' weight='bold'>
            GS25점포 공식 인증을 위해
            <br />
            점포코드를 등록해주세요!
          </Text>
        </BottomSheet.Title>
        <BottomSheet.Description>
          <Text variant='bodyMedium' color='$system_content_secondary'>
            점포코드는 포스기 메인화면 오른쪽 하단 환경설정 (톱니바퀴 모양)에서
            확인 가능합니다.
          </Text>
        </BottomSheet.Description>

        <StoreCodeEditForm
          onSubmit={() => {
            setOpen(false)

            queryClient.invalidateQueries(createMyStoreKey())

            toast.open({
              leftAccessary: 'positive',
              description: '점포코드를 성공적으로 등록했어요!',
            })
          }}
        />
      </BottomSheet.Content>
    </BottomSheet>
  )
}
