import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HXAP } from 'lib/bridge'
import { PURGE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export type EnteredStoreStateType = {
  storeId: number
  storeName: string
}

const initialState: EnteredStoreStateType = {
  storeId: 0,
  storeName: '',
}

export const enteredStoreStateSlice = createSlice({
  name: 'enteredStore',
  initialState,
  reducers: {
    setEnteredStore: (state, action: PayloadAction<EnteredStoreStateType>) => {
      state.storeId = action.payload.storeId
      state.storeName = action.payload.storeName

      HXAP.saveData<string>(
        'enteredStore',
        JSON.stringify({
          storeId: action.payload.storeId,
          storeName: action.payload.storeName,
        })
      )
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, state => {
      storage.removeItem('root')
    })
  },
})

export const { setEnteredStore } = enteredStoreStateSlice.actions

export default enteredStoreStateSlice.reducer
