import client from '../client'

export async function putStore({
  storeId,
  ...req
}: PutStoreParams & PutStoreReq) {
  const response = await client.put(`/v1/store/${storeId}`, req)

  return response.data
}

export type PutStoreParams = {
  storeId: number
}

export type PutStoreReq = {
  crn?: string
  name?: string
  tel?: string
  address?: string
  address2?: string
  type?: string
  lat?: number
  lng?: number
  storeCode?: string
  empty?: boolean
}
