import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MainRecommendTabType = 'recommend' | 'chat'

export type MainRecommendTabStateType = { tab: MainRecommendTabType }

const initialState: MainRecommendTabStateType = { tab: 'recommend' }

export const mainRecommendTabStateSlice = createSlice({
  name: 'mainRecommendTab',
  initialState,
  reducers: {
    setMainRecommendTab: (
      state,
      action: PayloadAction<MainRecommendTabType>
    ) => {
      state.tab = action.payload
    },
  },
})

export const { setMainRecommendTab } = mainRecommendTabStateSlice.actions

export default mainRecommendTabStateSlice.reducer
