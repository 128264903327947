import { useEffect } from 'react'

import useAlertDialog from 'hooks/useAlertDialog'
import { useAppSelector } from 'hooks/useReduxHook'

export function useDeletedStoreAlert() {
  const { deletedStore } = useAppSelector(store => store.deletedEnteredStore)
  const alertDialog = useAlertDialog()

  useEffect(() => {
    if (!deletedStore) return

    alertDialog.open({
      title: `${deletedStore?.storeName} 매장에서 자동 탈퇴 되었어요`,
      description:
        '매장이 삭제되었거나 사장님이 탈퇴 처리하셨어요. 문제가 생겼다면 고객 센터에 문의해 주세요',
      actionButton: {
        variant: 'fill',
        semantic: 'primary',
        label: '확인',
        onClick: () => alertDialog.close(),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
