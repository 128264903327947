import { callNative } from '../core/callNative'

/**
 * ## openCallbackView
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * Callback View를 띄웁니다.
 */
export async function openCallbackView<T>(
  callbackEventName: string,
  url: string
): Promise<T | null> {
  const result = await callNative(
    'openCallbackView',
    { callbackEventName, url },
    false
  )
  return result as T
}
