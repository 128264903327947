import { useEffect } from 'react'
import semverGt from 'semver/functions/gt'

import { HXAP } from 'lib/bridge'
import { AOS, IOS } from 'lib/data/nativeInfo'

import useAlertDialog from 'hooks/useAlertDialog'

export function useForceUpdateAlert() {
  const alertDialog = useAlertDialog()

  useEffect(() => {
    if (typeof window === 'undefined') return

    const platform = HXAP.getPlatform()
    if (platform === 'unknown') return

    const forceUpdate = platform === 'ios' ? IOS.forceUpdate : AOS.forceUpdate
    if (!forceUpdate) return

    const newestVersion = platform === 'ios' ? IOS.minVersion : AOS.minVersion
    ;(async () => {
      const currentVersion = await HXAP.getAppVersion()
      if (currentVersion === null) return
      if (!semverGt(newestVersion, currentVersion)) return

      alertDialog.open({
        title: '최신 업데이트 안내',
        description:
          '더욱 편리한 워키도키 이용을 위해 새로운 버전으로 업데이트 후 이용부탁드립니다. 감사합니다:)',
        actionButton: {
          variant: 'fill',
          semantic: 'primary',
          label: '확인',
          onClick: event => {
            event.preventDefault()
            const storeLink = platform === 'ios' ? IOS.storeLink : AOS.storeLink
            HXAP.openURL(storeLink)
          },
        },
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
