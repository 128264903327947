import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AddressType = {
  name: string
  detail: string
}

export type HostInitialStateType = {
  brandCode: string
  storeCode: string
  storeName: string
  address: AddressType
}

const initialState: HostInitialStateType = {
  brandCode: '',
  storeCode: '',
  storeName: '',
  address: {
    name: '',
    detail: '',
  },
}

export const hostInitialStateSlice = createSlice({
  name: 'hostInitialState',
  initialState,
  reducers: {
    setHostInitialBrandCode: (state, action: PayloadAction<string>) => {
      state.brandCode = action.payload
    },
    setHostInitialStoreCode: (state, action: PayloadAction<string>) => {
      state.storeCode = action.payload
    },
    setHostInitialStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload
    },
    setHostInitialAddress: (state, action: PayloadAction<AddressType>) => {
      state.address = action.payload
    },
    initHostInitialState: state => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setHostInitialBrandCode,
  setHostInitialStoreCode,
  setHostInitialStoreName,
  setHostInitialAddress,
  initHostInitialState,
} = hostInitialStateSlice.actions

export default hostInitialStateSlice.reducer
