import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import checklistOption, { ChecklistOptionType } from './checklistOption'
import deepLinkState, { DeepLinkStateType } from './deepLinkState'

import partTimerInitialState, {
  PartTimerInitialStateType,
} from './initialSetting/partTimer/partTimerInitialState'

import hostInitialState, {
  HostInitialStateType,
} from './initialSetting/host/hostInitialState'

import newJobPostingState, {
  NewJobPostingStateType,
} from './newJobPosting/newJobPostingState'

import enteredStoreState, { EnteredStoreStateType } from './enteredStoreState'

import myScheduleState, {
  MyScheduleStateType,
} from './todayRecommend/resume/schedule/myScheduleState'

import resumeAddressState, {
  ResumeAddressStateType,
} from './todayRecommend/resume/address/resumeAddressState'

import tempPageData, { TempPageDataType } from './tempPageData'

import inventoryFilter, {
  InventoryFilterType,
} from './shelfLife/inventoryFilter'
import deletedEnteredStore, {
  DeletedEnteredStoreType,
} from './deletedEnteredStore'
import globalAlertDialogState, {
  GlobalAlertDialogStateType,
} from './globalAlertDialogState'
import globalToastState, { GlobalToastStateType } from './globalToastState'
import copyChecklistState, {
  CopyChecklistStateType,
} from './todayChecklist/checklist/copy/copyChecklistState'
import checklistDateState, {
  ChecklistDateStateType,
} from './todayChecklist/checklist/checklistDateState'
import mainRecommendTabState, {
  MainRecommendTabStateType,
} from './todayRecommend/mainRecommendTabState'
import backState, { BackStateType } from './backState'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['enteredStoreState', 'checklistOption'],
}

export type StateType = {
  checklistOption: ChecklistOptionType
  memoSnackbar: {
    isOpen: boolean
    checkId: string
  }
  brandCreateInfo: {
    brand: string
    code: string
    name: string
    tel: string
    address: string
    lat: string
    lng: string
  }
  deepLinkState: DeepLinkStateType
  certificationInfo: {
    crn: string
    storeType: string
    address: string
  }
  partTimerInitialState: PartTimerInitialStateType
  hostInitialState: HostInitialStateType
  newJobPostingState: NewJobPostingStateType
  enteredStoreState: EnteredStoreStateType
  myScheduleState: MyScheduleStateType
  resumeAddressState: ResumeAddressStateType
  inventoryFilter: InventoryFilterType
  tempPageData: TempPageDataType
  deletedEnteredStore: DeletedEnteredStoreType
  globalAlertDialogState: GlobalAlertDialogStateType
  globalToastState: GlobalToastStateType
  copyChecklistState: CopyChecklistStateType
  checklistDateState: ChecklistDateStateType
  mainRecommendTabState: MainRecommendTabStateType
  backState: BackStateType
}

const reducer = (state: StateType | undefined, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.paylaod,
    }
  }
  return combineReducers({
    checklistOption,
    deepLinkState,
    partTimerInitialState,
    hostInitialState,
    newJobPostingState,
    enteredStoreState,
    myScheduleState,
    resumeAddressState,
    inventoryFilter,
    tempPageData,
    deletedEnteredStore,
    globalAlertDialogState,
    globalToastState,
    copyChecklistState,
    checklistDateState,
    mainRecommendTabState,
    backState,
  })(state, action)
}

export default persistReducer(persistConfig, reducer)
