import decoder from 'jwt-decode'
import { decodeAccessToken } from './decodeAccessToken'

/**
 * # isTokenExpired
 * @description
 * 토큰이 만료되었는지 확인합니다.
 *
 * @param {string} token access 혹은 refresh 토큰
 */
export function isTokenExpired(token: string): boolean {
  try {
    const decodedToken = decodeAccessToken(token)
    const tokenExp = decodedToken.exp
    const actualTimeInSeconds = new Date().getTime() / 1000
    const isValid = tokenExp > actualTimeInSeconds
    return !isValid
  } catch {
    return true
  }
}
