import { callNative } from '../core/callNative'

/**
 * ## getCodePushVersion
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 앱의 codepush version 값을 가져옵니다.
 *
 * @returns {string} 0
 */
export async function getCodePushVersion(): Promise<number> {
  const version = await callNative('getCodePushVersion')
  return version as number
}
