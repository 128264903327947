import { callNative } from '../core/callNative'

/**
 * ## openURL
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * CRM(카카오톡 채널)을 Native Device에서 엽니다.
 */
export async function showCRM(): Promise<void> {
  await callNative('showCRM')
}
