import { callNative } from '../core/callNative'

/**
 * ## getBarcode
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 바코드 촬영을 위한 카메라를 열고 인식된 값이나 입력한 값을 가져옵니다.
 *
 * @returns {string} 05012345678900
 */
export async function getBarcode(): Promise<string> {
  const barcode = await callNative('getBarcode', undefined, false)
  return barcode as string
}
