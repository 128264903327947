import { callNative } from '../core/callNative'
import { HapticFeedbackOptions, HapticPattern } from './types'

/**
 * ## hapticFeedback
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * haptic feedback을 발생시킵니다.
 */
export async function hapticFeedback(
  pattern: HapticPattern,
  options?: Partial<HapticFeedbackOptions>
): Promise<void> {
  await callNative('hapticFeedback', { pattern, options: options })
}
