import { Platform } from './types'

/**
 * ## getPlatform
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 현재 기기의 OS를 가져옵니다.
 *
 * @returns {string} android | ios | unknown
 */
export function getPlatform(): Platform {
  try {
    const isAndroid =
      navigator.userAgent.match(/Android/i) === null ? false : true
    if (isAndroid) return 'android'

    const isIOS =
      navigator.userAgent.match(/iPhone|iPad|iPod/i) === null ? false : true
    if (isIOS) return 'ios'

    return 'unknown'
  } catch {
    return 'unknown'
  }
}
