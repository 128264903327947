import {
  AlertDialogParamType,
  closeAlertDialog,
  loadingAlertDialog,
  openAlertDialog,
} from 'store/modules/globalAlertDialogState'
import { useAppDispatch } from './useReduxHook'
import { activeBackEvent, blockBackEvent } from 'store/modules/backState'

export default function useAlertDialog() {
  const dispatch = useAppDispatch()

  const open = (value: AlertDialogParamType) => {
    dispatch(openAlertDialog(value))
    dispatch(blockBackEvent())
  }

  const close = () => {
    dispatch(closeAlertDialog())
    dispatch(activeBackEvent())
  }

  const loading = () => {
    dispatch(loadingAlertDialog())
  }

  return {
    open,
    close,
    loading,
  }
}
