import {
  EnteredStoreStateType,
  setEnteredStore,
} from 'store/modules/enteredStoreState'

import { HXAP } from 'lib/bridge'
import { JwtTokenType } from 'lib/api/types'
import { putAccessToken } from 'lib/api/auth/putAccessToken'
import { getMyStore } from 'lib/api/store/getMyStore'

import { useAppDispatch } from './useReduxHook'

export function useEnterStore() {
  const dispatch = useAppDispatch()

  const enterStore = async (storeInfoOrId: EnteredStoreStateType | number) => {
    try {
      let tokens = await HXAP.loadData<JwtTokenType>('jwt', {
        accessToken: '',
        refreshToken: '',
      })

      let storeId: number = 0
      if (typeof storeInfoOrId === 'number') {
        storeId = storeInfoOrId
      } else {
        storeId = storeInfoOrId.storeId
      }
      const result = await putAccessToken({
        store_id: storeId,
        refresh_token: tokens.refreshToken,
        userOnlyYn: 'N',
      })
      tokens.accessToken = result.accessToken
      await HXAP.saveData('jwt', tokens)

      let storeName: string = ''
      if (typeof storeInfoOrId === 'number') {
        const storeInfo = await getMyStore()
        storeName = storeInfo.store_name
      } else {
        storeName = storeInfoOrId.storeName
      }

      const enteredStore: EnteredStoreStateType = {
        storeId,
        storeName,
      }

      dispatch(setEnteredStore(enteredStore))
    } catch (error) {
      console.error('enterStore', error)
    }
  }

  return { enterStore }
}
