import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from './useReduxHook'
import { setTempPageData } from '../store/modules/tempPageData'

export function useTempPageData<T>() {
  const dispatch = useAppDispatch()
  const { value } = useAppSelector(state => state.tempPageData)

  const save = useCallback(
    (value: T) => {
      dispatch(setTempPageData(JSON.stringify(value)))
    },
    [dispatch]
  )

  const load = useCallback((): T | null => {
    if (value === '') return null

    return JSON.parse(value) as T
  }, [value])

  return {
    save,
    load,
  }
}
