import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkTimeType } from 'components/v2/common/WorkTimeFieldForm/type'

export type MyScheduleStateType = {
  day: number[]
  time: WorkTimeType
  periodTagId: number
  periodLabel: string
  changed: boolean
  originState: {
    day: number[]
    time: WorkTimeType
    periodTagId: number
    periodLabel: string
  }
}

const initialState: MyScheduleStateType = {
  day: [],
  time: {},
  periodTagId: 0,
  periodLabel: '',
  changed: false,
  originState: {
    day: [],
    time: {},
    periodTagId: 0,
    periodLabel: '',
  },
}

export const myScheduleStateSlice = createSlice({
  name: 'myScheduleState',
  initialState,
  reducers: {
    setMySchedule: (
      state,
      action: PayloadAction<{
        day: number[]
        time: WorkTimeType
        periodTagId: number
        periodLabel: string
      }>
    ) => {
      state.originState = action.payload

      state.day = action.payload.day
      state.time = action.payload.time
      state.periodTagId = action.payload.periodTagId
      state.periodLabel = action.payload.periodLabel
    },
    setMyScheduleTime: (state, action: PayloadAction<WorkTimeType>) => {
      if (
        state.originState.time.startHour === action.payload.startHour &&
        state.originState.time.endHour === action.payload.endHour &&
        Object.hasOwn(state.originState.time, 'negotiable') &&
          state.originState.time.negotiable === action.payload.negotiable
      ) {
        state.changed = false
      } else {
        state.changed = true
      }

      state.time = action.payload
    },
    setMyScheduleDay: (state, action: PayloadAction<number[]>) => {
      if (
        JSON.stringify(state.originState.day.sort()) ===
        JSON.stringify(action.payload.sort())
      ) {
        state.changed = false
      } else {
        state.changed = true
      }

      state.day = action.payload
    },
    setMySchedulePeriod: (
      state,
      action: PayloadAction<{
        periodTagId: number
        periodLabel: string
      }>
    ) => {
      if (state.originState.periodTagId === action.payload.periodTagId) {
        state.changed = false
      } else {
        state.changed = true
      }

      state.periodTagId = action.payload.periodTagId
      state.periodLabel = action.payload.periodLabel
    },
    initMySchedule: state => {
      state.day = []
      state.time = {}
      state.periodTagId = 0
      state.periodLabel = ''
      state.changed = false
    },
  },
})

export const {
  setMySchedule,
  setMyScheduleTime,
  setMyScheduleDay,
  setMySchedulePeriod,
  initMySchedule,
} = myScheduleStateSlice.actions

export default myScheduleStateSlice.reducer
