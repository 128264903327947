import axios from 'axios'
import { serverSideInterceptor } from 'lib/utils/axios/serverSideInterceptor'
import { clientSideInterceptor } from 'lib/utils/axios/clientSideInterceptor'

const IS_SERVER = typeof window === 'undefined'
const BASE_URL = IS_SERVER
  ? process.env.SERVER_BASE_URL
  : process.env.NEXT_PUBLIC_BASE_URL

const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

client.interceptors.request.use(async config => {
  if (IS_SERVER) {
    return serverSideInterceptor(config)
  } else {
    return clientSideInterceptor(config)
  }
})

export default client
