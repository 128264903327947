import { callNative } from '../core/callNative'

/**
 * ## openInAppBrowser
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * in-app 브라우저에서 페이지를 띄웁니다.
 */
export async function openInAppBrowser(url: string): Promise<void> {
  await callNative('openInAppBrowser', { url })
}
