import client from '../client'

export async function putInvitation(req: PutInvitationReq) {
  const response = await client.put<undefined>('/v1/store/invitation', req)

  return response.data
}

export type PutInvitationReq = {
  code: string
  user_type: string
}
