import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const BEFORE_EXPIRY_TAB = 'beforeExpiryTab'
export const AFTER_EXPIRY_TAB = 'afterExpiryTab'

export type InventoryTabType = 'beforeExpiryTab' | 'afterExpiryTab'

export type InventoryFilterType = {
  tab: InventoryTabType
  userFilter: 'all' | 'mine'
  // basic은 임박 상품은 소비기한 임박 순, 만료 상품은 최근 만료된 순
  arrangeFilter: 'basic' | 'recentRegistered'
}

const initialState: InventoryFilterType = {
  tab: 'beforeExpiryTab',
  userFilter: 'all',
  arrangeFilter: 'basic',
}

export const inventoryFilterSlice = createSlice({
  name: 'inventoryFilter',
  initialState,
  reducers: {
    setInventoryTab: (state, action: PayloadAction<InventoryTabType>) => {
      state.tab = action.payload
    },
    filterMine: state => {
      state.userFilter = 'mine'
    },
    filterAll: state => {
      state.userFilter = 'all'
    },
    sortRecent: state => {
      state.arrangeFilter = 'recentRegistered'
    },
    sortBasic: state => {
      state.arrangeFilter = 'basic'
    },
  },
})

export const {
  setInventoryTab,
  filterMine,
  filterAll,
  sortRecent,
  sortBasic,
} = inventoryFilterSlice.actions

export default inventoryFilterSlice.reducer
