import { keyframes, styled } from '@higherx/stitches-config'
import React, { memo } from 'react'

const LoadingSpinner = (): JSX.Element => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Container onClick={onClick}>
      <LoadingBox>
        <Loading />
      </LoadingBox>
    </Container>
  )
}

const spinner = keyframes({
  '0%': {
    '-webkit-transform': 'rotate(0deg)',
    transform: 'rotate(0deg)',
  },
  '100%': {
    '-webkit-transform': 'rotate(360deg)',
    transform: 'rotate(360deg)',
  },
})

const Container = styled('section', {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 999,
  overflow: 'hidden',
  touchAction: 'none',
})

const LoadingBox = styled('div', {
  width: 60,
  height: 60,
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%, -50%)',
  padding: 12,
  backgroundColor: 'rgba(0,0,0,0.5)',
  borderRadius: 16,
})

const Loading = styled('div', {
  width: 36,
  height: 36,
  borderWidth: 3,
  borderStyle: 'solid',
  borderColor: '$system_semantic_primary_default',
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: `${spinner} 1s linear infinite`,
})

export default memo(LoadingSpinner)
