import { callNative } from '../core/callNative'
import { GeoLocation } from './types'

/**
 * ## getGeoLocation
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 사용자의 현재 좌표를 가져옵니다.
 *
 * @returns {GeoLocation | null} { lat: 0.000000, lon: 0.00000 } or null
 */
export async function getGeoLocation(): Promise<GeoLocation | null> {
  const location = await callNative('getGeoLocation')
  return location as GeoLocation
}
