import { getMyStore } from 'lib/api/store/getMyStore'
import { StoreType } from 'lib/api/store/types'
import { useCallback } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

export const createMyStoreKey = () => ['my_store']

export default function useMyStoreQuery(
  value?: any,
  options?: UseQueryOptions<StoreType, unknown, StoreType, string[]>
) {
  return useQuery(createMyStoreKey(), () => getMyStore(), { ...options })
}

export function useMyStoreQueryGetter() {
  const queryClient = useQueryClient()

  const get = useCallback(() => {
    return queryClient.getQueryData<StoreType>(createMyStoreKey())
  }, [queryClient])

  return get
}
