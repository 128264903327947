import { Router } from 'next/router'
import { useEffect, useState } from 'react'

export function useSpinnerRouteEvent(router: Router) {
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const handleRouteChangeStart = (url: any) => {
      setLoading(true)
    }

    const handleRouteChangeComplete = () => {
      setLoading(false)
    }

    const handleRouteChangeError = (error: Error) => {
      setLoading(false)
      console.error(`route error: ${error.name}: ${error.message}`)
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart)
    Router.events.on('routeChangeComplete', handleRouteChangeComplete)
    Router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart)
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
      Router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [])

  return {
    isPageLoading: isLoading,
  }
}
