import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImageType } from 'components/v2/common/GridPhotoInputForm'
import { WorkTimeType } from 'components/v2/common/WorkTimeFieldForm/type'

type AddressType = {
  address: string
  sidoName: string
  sigunguName: string
  dongName: string
  sigunguCode: string
  dongCode: string
}

type CareerType = {
  storeName: string
  startMonth: string
  endMonth: string
  storeType: string
  defaultChecklistIds: number[]
  customChecklist: string[]
}

export type PartTimerInitialStateType = {
  address?: AddressType
  distance: number
  time?: WorkTimeType
  day: number[]
  strengthsTagIds: number[]
  introduction: string
  noCareer: boolean
  career: CareerType
  careerImages: ImageType[]
}

const careerInitState = {
  storeName: '',
  storeType: '',
  startMonth: '',
  endMonth: '',
  defaultChecklistIds: [],
  customChecklist: [],
}

const initialState: PartTimerInitialStateType = {
  address: undefined,
  distance: 3,
  time: undefined,
  day: [],
  strengthsTagIds: [],
  introduction: '',
  noCareer: false,
  career: careerInitState,
  careerImages: [],
}

export const partTimerInitialStateSlice = createSlice({
  name: 'partTimerInitialState',
  initialState,
  reducers: {
    setPartTimerInitialAddress: (state, action: PayloadAction<AddressType>) => {
      state.address = action.payload
    },
    setPartTimerInitialDistance: (state, action: PayloadAction<number>) => {
      state.distance = action.payload
    },
    setPartTimerInitialTime: (state, action: PayloadAction<WorkTimeType>) => {
      state.time = action.payload
    },
    setPartTimerInitialDay: (state, action: PayloadAction<number[]>) => {
      state.day = action.payload
    },
    setPartTimerInitialStrengthsTagIds: (
      state,
      action: PayloadAction<number[]>
    ) => {
      state.strengthsTagIds = action.payload
    },
    setPartTimerInitialIntroduction: (state, action: PayloadAction<string>) => {
      state.introduction = action.payload
    },
    setPartTimerInitialNoCareer: state => {
      state.noCareer = true
      state.career = careerInitState
    },
    setPartTimerInitialCareer: (state, action: PayloadAction<CareerType>) => {
      state.noCareer = false
      state.career = action.payload
    },
    setPartTimerInitialCareerImages: (
      state,
      action: PayloadAction<ImageType[]>
    ) => {
      state.careerImages = action.payload
    },
  },
})

export const {
  setPartTimerInitialAddress,
  setPartTimerInitialDistance,
  setPartTimerInitialTime,
  setPartTimerInitialDay,
  setPartTimerInitialStrengthsTagIds,
  setPartTimerInitialIntroduction,
  setPartTimerInitialNoCareer,
  setPartTimerInitialCareer,
  setPartTimerInitialCareerImages,
} = partTimerInitialStateSlice.actions

export default partTimerInitialStateSlice.reducer
