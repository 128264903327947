import { useState } from 'react'
import { useRouter } from 'next/router'
import { AxiosError } from 'axios'

import useAlertDialog from 'hooks/useAlertDialog'
import { useEnterStore } from 'hooks/useEnterStore'

import { getInvitationReceiver } from 'lib/api/invitation/getInvitationReceiver'
import { putInvitation } from 'lib/api/invitation/putInvitation'
import { postJobSearchMode } from 'lib/api/user/postJobSearchMode'

import { HXAPSystemEventHandler } from './types'
import { callRouteComplete } from '../utils/callRouteComplete'

interface InvitationPayload {
  code: string
}

export function useInvitation(): HXAPSystemEventHandler {
  const [processing, setProcessing] = useState<boolean>(false)

  const alertDialog = useAlertDialog()
  const { enterStore } = useEnterStore()
  const { replace } = useRouter()

  const processInvitation = async (args: InvitationPayload): Promise<void> => {
    const code = args.code
    setProcessing(true)

    try {
      const receiver = await getInvitationReceiver({
        code,
      })
      await putInvitation({
        code,
        user_type: receiver.user_type,
      })

      const enteredStore = {
        storeId: receiver.store_id,
        storeName: receiver.store_name,
      }

      await enterStore(enteredStore)
      await postJobSearchMode({
        jobSearchMode: 'EMPLOYEE',
      })
      replace('/todayChecklist')

      alertDialog.open({
        title: '店舗への合流を完了しました！',
        actionButton: {
          variant: 'fill',
          semantic: 'primary',
          label: '確認する',
          onClick: () => {
            alertDialog.close()
            replace('/todayChecklist')
          },
        },
      })
    } catch (error) {
      const axiosError = error as AxiosError
      if (!axiosError) {
        alertDialog.open({
          // 초대장 사용 불가
          title: '招待状接続エラー',
          // 잠시 후 다시 시도해주세요
          description: 'しばらくしてからもう一度お試しください',
          actionButton: {
            variant: 'fill',
            semantic: 'primary',
            // 확인
            label: '確認する',
            onClick: () => alertDialog.close(),
          },
        })
        return
      }

      switch (axiosError.response?.status) {
        case 409:
          alertDialog.open({
            // 이미 합류한 매장이에요
            title: 'すでに合流した店舗です',
            actionButton: {
              variant: 'fill',
              semantic: 'primary',
              // 확인
              label: '確認する',
              onClick: () => alertDialog.close(),
            },
          })
          break
        default:
          alertDialog.open({
            // 초대장을 사용할 수 없어요!
            title: 'この招待状は使用できません！！',
            description:
              // 초대 링크가 24시간이 지났거나, 이미 삭제된 화면이에요. 사장님에게 다시 초대를 요청해 주세요
              '招待リンクが 24 時間経過したか、すでに削除された画面です。社長にもう一度招待をリクエストしてください。',
            actionButton: {
              variant: 'fill',
              semantic: 'primary',
              // 확인
              label: '確認する',
              onClick: () => alertDialog.close(),
            },
          })
          break
      }
    } finally {
      setProcessing(false)
    }
  }

  const handle = async (args: InvitationPayload) => {
    if (window.location.pathname === '/') {
      callRouteComplete(() => processInvitation(args))
    } else {
      processInvitation(args)
    }
  }

  return {
    needLogin: true,
    eventName: 'invite',
    handle,
    processing,
  }
}
