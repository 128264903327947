import { Button, ButtonProps } from './Button'
import Text from 'components/v2/common/Text'
import { keyframes, styled } from '@higherx/stitches-config'

import * as RadixAlertDialog from '@radix-ui/react-alert-dialog'

export const AlertDialogPrimitive = RadixAlertDialog

export type AlertDialogProps = React.ComponentProps<
  typeof RadixAlertDialog.Root
>

export type AlertDialogContentProps = React.ComponentProps<
  typeof RadixAlertDialog.Content
>

export type AlertDialogTitleProps = React.ComponentProps<
  typeof RadixAlertDialog.Title
>

export type AlertDialogDescriptionProps = React.ComponentProps<
  typeof RadixAlertDialog.Description
>

const AlertDialogTrigger = RadixAlertDialog.Trigger

function AlertDialogContent({ children, ...props }: AlertDialogContentProps) {
  return (
    <RadixAlertDialog.Portal>
      <AlertDialogOverlay />

      <StyledAlertDialogContent {...props}>{children}</StyledAlertDialogContent>
    </RadixAlertDialog.Portal>
  )
}

function AlertDialogTitle({ children, ...props }: AlertDialogTitleProps) {
  return (
    <StyledAlertDialogTitle {...props}>
      <Text variant='titleSmall' weight='bold'>
        {children}
      </Text>
    </StyledAlertDialogTitle>
  )
}

function AlertDialogDescription({
  children,
  ...props
}: AlertDialogDescriptionProps) {
  return (
    <StyledAlertDialogDescription {...props}>
      <Text variant='bodyMedium' color='$system_content_secondary'>
        {children}
      </Text>
    </StyledAlertDialogDescription>
  )
}

const AlertDialogButtons = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingTop: 8,

  '& > button': {
    width: '100%',
  },

  '& > button + button': {
    marginLeft: 8,
  },
})

function AlertDialogActionButton({ children, ...props }: ButtonProps) {
  return (
    <StyledAlertDialogActionButton asChild>
      <Button {...props}>{children}</Button>
    </StyledAlertDialogActionButton>
  )
}

function AlertDialogCancelButton({ children, ...props }: ButtonProps) {
  return (
    <StyledAlertDialogCancelButton asChild>
      <Button {...props}>{children}</Button>
    </StyledAlertDialogCancelButton>
  )
}

function AlertDialogMain({ children, ...props }: AlertDialogProps) {
  return <RadixAlertDialog.Root {...props}>{children}</RadixAlertDialog.Root>
}

export const AlertDialog = Object.assign(AlertDialogMain, {
  Trigger: AlertDialogTrigger,
  Content: AlertDialogContent,
  Title: AlertDialogTitle,
  Description: AlertDialogDescription,
  Buttons: AlertDialogButtons,
  ActionButton: AlertDialogActionButton,
  CancelButton: AlertDialogCancelButton,
})

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.5 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const AlertDialogOverlay = styled(RadixAlertDialog.Overlay, {
  backgroundColor: '$ref_black',
  opacity: 0.5,
  position: 'fixed',
  inset: 0,
  zIndex: 10000000,
  animation: `${overlayShow} 0.3s cubic-bezier(0.16, 1, 0.3, 1)`,
})

const StyledAlertDialogContent = styled(RadixAlertDialog.Content, {
  backgroundColor: '$component_alert-dialog_background',
  borderRadius: '$component_alert-dialog_border-radius',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '$component_alert-dialog_width',
  maxWidth: '$component_alert-dialog_width',
  padding: 16,
  paddingTop: 24,
  zIndex: 10000001,
  animation: `${contentShow} 0.3s cubic-bezier(0.16, 1, 0.3, 1)`,
})

const StyledAlertDialogTitle = styled(RadixAlertDialog.Title, {
  margin: 0,
  marginBottom: 12,
})

const StyledAlertDialogDescription = styled(RadixAlertDialog.Description, {
  margin: 0,
  marginBottom: 16,
})

export const StyledAlertDialogActionButton = styled(RadixAlertDialog.Action, {
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  },
})

export const StyledAlertDialogCancelButton = styled(RadixAlertDialog.Cancel, {
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  },
})
