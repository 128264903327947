import { useEffect } from 'react'

import { BridgeService } from './core'
import { useInvitation } from './event/useInvitation'
import { useNotification } from './event/useNotification'
import { useShinhanMyShop } from './event/channel/shinhan/useShinhanMyShop'
import { useShinhanMyShopOpenPage } from './event/channel/shinhan/useShinhanMyShopOpenPage'

export function useHXAP() {
  const inviteHandler = useInvitation()
  const notificationHandler = useNotification()
  // const shinhanMyShopConnectHandler = useShinhanMyShop()
  // const shinhanMyShopOpenPageHandler = useShinhanMyShopOpenPage()

  useEffect(() => {
    BridgeService.register(
      inviteHandler,
      notificationHandler
      // shinhanMyShopConnectHandler,
      // shinhanMyShopOpenPageHandler
    )

    return () => {
      BridgeService.unregister()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    processing: inviteHandler.processing || notificationHandler.processing,
    // shinhanMyShopConnectHandler.processing ||
    // shinhanMyShopOpenPageHandler.processing,
  }
}
