import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImageType } from 'components/v2/common/GridPhotoInputForm'
import { WorkTimeType } from 'components/v2/common/WorkTimeFieldForm/type'

export type PayMethodType = 'HOURLY' | 'PER_CASE'

type InfoType = {
  boast: string[]
  customBoast: string[]
}

export type NewJobPostingStateType = {
  storeId: number
  storeType: string
  payMethod: PayMethodType
  pay?: number
  time?: WorkTimeType
  day: number[]
  periodTagId: number
  defaultChecklistIds: number[]
  customChecklist: string[]
  storeImages: ImageType[]
  isInitial: boolean
  // 공고 추가 시, 새 매장 추가 후 공고를 추가한 경우 true
  continuedNewStore: boolean
} & InfoType

const initialState: NewJobPostingStateType = {
  storeId: 0,
  storeType: '',
  payMethod: 'HOURLY',
  pay: undefined,
  time: undefined,
  day: [],
  periodTagId: 15,
  defaultChecklistIds: [],
  customChecklist: [],
  boast: [],
  customBoast: [],
  storeImages: [],
  isInitial: false,
  continuedNewStore: false,
}

export const newJobPostingStateSlice = createSlice({
  name: 'newJobPosting',
  initialState,
  reducers: {
    setContinuedNewStore: (state, action: PayloadAction<boolean>) => {
      state.continuedNewStore = action.payload
    },
    setNewJobPostingStore: (
      state,
      action: PayloadAction<{ storeId: number; storeType: string }>
    ) => {
      state.storeId = action.payload.storeId
      state.storeType = action.payload.storeType
    },
    setNewJobPostingAsInitial: state => {
      state.isInitial = true
    },
    setNewJobPostingPay: (
      state,
      action: PayloadAction<{ payMethod: PayMethodType; pay: number }>
    ) => {
      state.payMethod = action.payload.payMethod
      state.pay = action.payload.pay
    },
    setNewJobPostingTime: (state, action: PayloadAction<WorkTimeType>) => {
      state.time = action.payload
    },
    setNewJobPostingDay: (state, action: PayloadAction<number[]>) => {
      state.day = action.payload
    },
    setNewJobPostingPeriodTagId: (state, action: PayloadAction<number>) => {
      state.periodTagId = action.payload
    },
    setNewJobPostingChecklist: (
      state,
      action: PayloadAction<{
        defaultChecklistIds: number[]
        customChecklist: string[]
      }>
    ) => {
      state.defaultChecklistIds = [...action.payload.defaultChecklistIds]
      state.customChecklist = [...action.payload.customChecklist]
    },
    setNewJobPostingInfo: (state, action: PayloadAction<InfoType>) => {
      state.boast = [...action.payload.boast]
      state.customBoast = [...action.payload.customBoast]
    },
    setNewJobPostingStoreImages: (
      state,
      action: PayloadAction<ImageType[]>
    ) => {
      state.storeImages = action.payload
    },
    initNewJobPostingState: state => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setContinuedNewStore,
  setNewJobPostingStore,
  setNewJobPostingAsInitial,
  setNewJobPostingPay,
  setNewJobPostingTime,
  setNewJobPostingDay,
  setNewJobPostingPeriodTagId,
  setNewJobPostingChecklist,
  setNewJobPostingInfo,
  setNewJobPostingStoreImages,
  initNewJobPostingState,
} = newJobPostingStateSlice.actions

export default newJobPostingStateSlice.reducer
