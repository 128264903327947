import { callNative } from '../core/callNative'
import { WifiInfo } from './types'
/**
 * ## getCurrentWifi
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 현재 연결된 Wi-Fi의 정보를 가져옵니다.
 *
 * @returns {WifiInfo | null} { ssid: Wi-Fi 이름, bssid: Wi-Fi 식별자 } or null
 */
export async function getCurrentWifi(): Promise<WifiInfo | null> {
  const wifiInfo = await callNative('getCurrentWifi')
  return wifiInfo as WifiInfo
}
