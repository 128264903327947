import { callNative } from '../core/callNative'

/**
 * ## loadData
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 기기에 저장한 값을 불러옵니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 null
 */
export async function loadData<T = any>(key: string): Promise<T | null>

/**
 * ## loadData
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 기기에 저장한 값을 불러옵니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 defaultValue
 */
export async function loadData<T = any>(
  key: string,
  defaultValue?: T
): Promise<T>

export async function loadData<T = any>(
  key: string,
  defaultValue?: T
): Promise<T | null> {
  const data = (await callNative('loadData', { key })) as T
  return data ?? defaultValue ?? null
}
