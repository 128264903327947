import { styled, config } from "@higherx/stitches-config"
import { Icon } from "@higherx/hds-icon"
import type { IconNameType } from "@higherx/hds-icon"
import { CSS, PropertyValue } from "@stitches/react"
import { forwardRef } from "react"

export type IconButtonProps = {
  iconName: IconNameType
  size: "large" | "medium" | "small" | "xSmall"
  color?: PropertyValue<"color", typeof config>
  css?: CSS<typeof config>
  disabled?: boolean
} & Omit<React.HTMLAttributes<HTMLButtonElement>, "type">

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton({ iconName, size, color, disabled, ...props }, ref) {
    return (
      <StyledIconButton
        size={size}
        type="button"
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <Icon name={iconName} size="standard" color={color} />
      </StyledIconButton>
    )
  }
)

const StyledIconButton = styled("button", {
  cursor: "pointer",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  color: "inherit",

  variants: {
    size: {
      large: {
        minWidth: 48,
        width: 48,
        height: 48,
      },
      medium: {
        minWidth: 40,
        width: 40,
        height: 40,
      },
      small: {
        minWidth: 32,
        width: 32,
        height: 32,
      },
      xSmall: {
        minWidth: 28,
        width: 28,
        height: 28,
      },
    },
  },
})
