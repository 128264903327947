import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ShinhanDeepLinkDataType = {
  openBottomSheet: boolean
  crn?: string
  code?: string
  groupId?: string
} | null

export type DeepLinkStateType = {
  shinhan: ShinhanDeepLinkDataType
  inviteCode: string | null
}

const initialState: DeepLinkStateType = {
  shinhan: null,
  inviteCode: null,
}

const deepLinkState = createSlice({
  name: 'deepLinkState',
  initialState,
  reducers: {
    setInviteCode: (state, action: PayloadAction<string | null>) => {
      state.inviteCode = action.payload
    },
    setShinhan: (state, action: PayloadAction<ShinhanDeepLinkDataType>) => {
      state.shinhan = action.payload
    },
    setShinhanBottomSheet: (state, action: PayloadAction<boolean>) => {
      if (state.shinhan) {
        state.shinhan.openBottomSheet = action.payload
      }
    },
  },
})

export const { setInviteCode, setShinhan, setShinhanBottomSheet } =
  deepLinkState.actions

export default deepLinkState.reducer
