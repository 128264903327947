import { callNative } from '../core/callNative'

/**
 * ## getPushToken
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * firebase push token을 가져옵니다.
 *
 * @returns {string} f9Wsss5oDU6ddfdWZz4yr5:APA91bHtHxwaG9_wFhugIAeWusssslm7nzOVWXM
 */
export async function getPushToken(): Promise<string | undefined> {
  const result = (await callNative('getPushToken')) as string
  if (!result) {
    return undefined
  }
  return result
}
