import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'

import { BottomCTA } from 'components/v2/designSystem/BottomCTA'
import { BottomSheet } from 'components/v2/designSystem/BottomSheet'
import { Button } from 'components/v2/designSystem/Button'
import Text from 'components/v2/common/Text'

import { styled } from '@higherx/stitches-config'
import { setShinhanBottomSheet } from 'store/modules/deepLinkState'
import { useRouter } from 'next/router'
import { useLoginState } from 'hooks/useLoginState'

export function ShinhanConnectBottomSheet(): JSX.Element {
  const { isLogin } = useLoginState()
  const { push } = useRouter()
  const { shinhan } = useAppSelector(store => store.deepLinkState)
  const dispatch = useAppDispatch()

  const showBottomSheet = isLogin && shinhan?.openBottomSheet

  const handleConnect = () => {
    dispatch(setShinhanBottomSheet(false))
    push('/channel/shinhan/term')
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      dispatch(setShinhanBottomSheet(false))
    }
  }

  return (
    <BottomSheet
      open={showBottomSheet}
      height='fitContent'
      onOpenChange={handleOpenChange}
    >
      <BottomSheet.Content>
        <BottomSheet.Title>
          <Text variant='titleLarge' weight='bold'>
            사장님! 신한 My Shop도
            <br />
            연동해 보시겠어요?
          </Text>
        </BottomSheet.Title>
        <Content>
          <Text variant='bodyMedium'>
            신한 My Shop을 연동하면 카드사에서 제공하는 다양한 헤택을 받아볼 수
            있어요.
          </Text>
        </Content>
        <BottomCTA
          primaryButton={
            <BottomSheet.Close asChild>
              <Button
                variant='fill'
                semantic='primary'
                size='large'
                onClick={handleConnect}
              >
                연동하기
              </Button>
            </BottomSheet.Close>
          }
          secondaryButton={
            <BottomSheet.Close asChild>
              <Button variant='line' semantic='neutral' size='large'>
                취소
              </Button>
            </BottomSheet.Close>
          }
        />
      </BottomSheet.Content>
    </BottomSheet>
  )
}

const Content = styled('div', {
  padding: '0 16px',
})
