import client from '../client'
import { BoardType } from './types'

export async function getBoard({ board_id }: GetBoardParams) {
  const response = await client.get<BoardType>(`/v1/store/board/${board_id}`)

  return response.data
}

export type GetBoardParams = {
  board_id: number
}
