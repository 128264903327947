import { getStoreList } from 'lib/api/store/getStoreList'
import { StoreListType } from 'lib/api/store/types'
import { useCallback } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

export const createStoreListKey = () => ['storeList']

export default function useStoreListQuery(
  value?: any,
  options?: UseQueryOptions<StoreListType, unknown, StoreListType, string[]>,
) {
  return useQuery(createStoreListKey(), () => getStoreList(), { ...options })
}

export function useStoreListQueryGetter() {
  const queryClient = useQueryClient()

  const get = useCallback(() => {
    return queryClient.getQueryData<StoreListType>(createStoreListKey())
  }, [queryClient])

  return get
}
