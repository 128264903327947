import { ToastProps } from 'components/v2/designSystem/Toast'
import { closeToast, openToast } from 'store/modules/globalToastState'
import { useAppDispatch } from './useReduxHook'
import { deleteOverlayStack, pushOverlayStack } from 'store/modules/backState'

export default function useToast() {
  const dispatch = useAppDispatch()

  const open = (value: ToastProps) => {
    dispatch(openToast(value))
    dispatch(pushOverlayStack('toast'))
  }

  const close = () => {
    dispatch(closeToast())
    dispatch(deleteOverlayStack('toast'))
  }

  return {
    open,
    close,
  }
}
