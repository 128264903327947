import { safeLocalStorage } from 'lib/utils/storage'

/**
 * ## virtualSaveData
 *
 * @description
 * 기기에 값을 저장합니다.
 */
export async function virtualSaveData<T = any>(
  key: string,
  value: T
): Promise<void> {
  safeLocalStorage.set(key, JSON.stringify(value))
}
