import { callNative } from '../core/callNative'

/**
 * ## exitApp
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 앱을 종료합니다.
 */
export async function exitApp(): Promise<void> {
  await callNative('exitApp', undefined, false)
}
