/**
 * ## virtualGetPushToken
 *
 * @description
 * firebase push token을 가져옵니다.
 * virtual layer에서는 로그인을 위해 notSupportedAction으로 정의하지 않고 항상 undefined를 반환함
 *
 * @returns {string} f9Wsss5oDU6ddfdWZz4yr5:APA91bHtHxwaG9_wFhugIAeWusssslm7nzOVWXM
 */
export async function virtualGetPushToken(): Promise<string | undefined> {
  return undefined
}
