import decoder from 'jwt-decode'

export type AccessTokenPayload = {
  aud: string
  exp: number
  iat: number
  tokenType: string
  userInfo: TokenUserInfo
}

type TokenUserInfo = {
  userId: number
  roles: string[]
  userType?: string
  deviceId?: string
  storeId?: number
  brandId?: number
  timezone: string
}

type TokenPayload = {
  aud: string
  exp: number
  iat: number
  tokenType: string
  userInfo: string
}

export function decodeAccessToken(accessToken: string): AccessTokenPayload {
  const decodedToken: TokenPayload = decoder(accessToken)
  const userInfo: TokenUserInfo = JSON.parse(decodedToken.userInfo)

  return {
    ...decodedToken,
    userInfo: userInfo,
  }
}
