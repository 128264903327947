import { useState } from 'react'
import { useRouter } from 'next/router'

import useAlertDialog from 'hooks/useAlertDialog'
import { useAppDispatch } from 'hooks/useReduxHook'
import { useEnterStore } from 'hooks/useEnterStore'

import { NotificationTagType } from 'lib/api/notification/types'

import { HXAPSystemEventHandler } from './types'
import { callRouteComplete } from '../utils/callRouteComplete'
import dayjs from 'dayjs'
import { useTempPageData } from 'hooks/useTempPageData'
import { NotificationTempData } from 'pages/notification'
import { getBoard } from 'lib/api/board/getBoard'
import { getInventory } from 'lib/api/shelfLife/getInventory'
import { setInventoryTab } from 'store/modules/shelfLife/inventoryFilter'
import Sentry from '@sentry/nextjs'
import { useQueryClient } from 'react-query'

import { setSelectedCategoryId } from '../../../store/modules/checklistOption'
import { setChecklistDate } from '../../../store/modules/todayChecklist/checklist/checklistDateState'

interface NotificationPayload {
  tag: NotificationTagType
  param: string
}

interface NotificationParams {
  store_id?: string | number
  user_id?: string | number
  checklist_id?: string
  date?: string
  folder_id?: string
  board_id?: string | number
  category_id?: string
  inventory_id?: string | number
  chat_room_id?: string
}

function getFixedNumber(value: any): number {
  if (typeof value !== 'number') {
    return Number(value)
  }
  return value
}

function getVariableName(variable: any | undefined, name: string): string {
  return !variable ? name : ''
}

async function checkBoardPermission(boardId: number): Promise<boolean> {
  try {
    await getBoard({
      board_id: boardId,
    })
    return true
  } catch {
    return false
  }
}

export function useNotification(): HXAPSystemEventHandler {
  const [processing, setProcessing] = useState<boolean>(false)

  const { push, asPath } = useRouter()

  const queryClient = useQueryClient()
  const alertDialog = useAlertDialog()

  const dispatch = useAppDispatch()
  const { enterStore } = useEnterStore()

  const { save: notificationTempSave } = useTempPageData<NotificationTempData>()

  const handleBoard = async (
    folderId: string,
    boardId: number
  ): Promise<void> => {
    const canAccess = await checkBoardPermission(boardId)
    if (!canAccess) {
      alertDialog.open({
        // 알림
        title: 'お知らせ',
        description:
          // 전달사항 혹은 폴더를 찾지 못했거나 권한이 없습니다.
          'お知らせまたはフォルダーが見つからないか、\n権限がありません。',
        actionButton: {
          variant: 'fill',
          semantic: 'primary',
          // 확인
          label: '確認',
          onClick: () => alertDialog.close(),
        },
      })
      return
    }
    await push(`/folderList/${folderId}/${boardId}`)
  }

  const handleBarcodeTimelyAlarm = async (inventoryId: number) => {
    const itemInfo = await getInventory({
      inventory_id: inventoryId,
    })
    const expiryDate = itemInfo.inventory_expiry_date
    const expiryTime = itemInfo.inventory_expiry_time
    const expiryDateTime = dayjs(`${expiryDate} ${expiryTime}`)

    if (expiryDateTime.isBefore(dayjs())) {
      dispatch(setInventoryTab('afterExpiryTab'))
    } else {
      dispatch(setInventoryTab('beforeExpiryTab'))
    }

    await push('/shelfLife')
  }

  const processNotification = async (
    args: NotificationPayload
  ): Promise<void> => {
    console.log('test', args)
    const {
      store_id,
      user_id,
      checklist_id: checklistId,
      date,
      folder_id: folderId,
      board_id,
      category_id: categoryId,
      inventory_id,
      chat_room_id: chatRoomId,
    }: NotificationParams = JSON.parse(args.param ?? '{}')
    const tag = args.tag
    try {
      setProcessing(true)

      let storeId = store_id
      if (typeof storeId === 'string') {
        storeId = Number(storeId)
      }

      let userId = user_id
      if (typeof userId === 'string') {
        userId = Number(userId)
      }

      let boardId = board_id
      if (typeof boardId === 'string') {
        boardId = Number(boardId)
      }

      let inventoryId = inventory_id
      if (typeof inventoryId === 'string') {
        inventoryId = Number(inventory_id)
      }

      if (storeId) {
        await enterStore(storeId)
      }

      switch (tag) {
        case 'JOIN_INVITATION':
        case 'JOIN_REQUEST':
        case 'JOIN_OKAY':
        case 'MEMBER_LEAVE_MANAGER':
          if (!storeId) throw `${tag} is need storeId`
          push(`/todayChecklist/member`)
          break

        case 'MEMBER_PROMOTION':
          if (!(userId && storeId))
            throw `${tag} is need ${getVariableName(
              userId,
              'userId'
            )} ${getVariableName(storeId, 'storeId')}`
          push(`/todayChecklist/member/${userId}`)
          break

        case 'MEMBER_LEAVE':
          if (!storeId) throw `${tag} is need storeId`
          push(`/todayChecklist`)
          break
        case 'CHECKLIST_NEW':
        case 'CHECKLIST_UPDATE':
        case 'CHECKLIST_UNCHECKED':
        case 'CHECKLIST_REMIND':
        case 'CHECK_NEW':
        case 'CHECK_NEW_COMMENT':
        case 'CHECK_NEW_SUBCOMMENT':
          if (!(checklistId && date && categoryId))
            throw new Error(
              `${tag} is need ${getVariableName(
                checklistId,
                'checklistId'
              )} ${getVariableName(date, 'date')} ${getVariableName(
                categoryId,
                'categoryId'
              )}`
            )
          dispatch(setSelectedCategoryId(categoryId))
          dispatch(setChecklistDate(dayjs(date).format('YYYY-MM-DD')))

          push(`/todayChecklist/checklist/${checklistId}`)
          break

        case 'CATEGORY_NEW':
        case 'CATEGORY_UPDATE':
          if (!storeId) throw `${tag} is need storeId`

          push(`/todayChecklist/categoryList`)
          break
        case 'BOARD_FOLDER_NEW':
        case 'BOARD_FOLDER_UPDATE':
        case 'BOARD_NOT_READ':
          push(`/folderList`)
          break
        case 'BOARD_NEW':
        case 'BOARD_NEW_COMMENT':
        case 'BOARD_REMIND':
          // 위쪽의 enteredStore를 실행시키기 위해 storeId가 있어야함
          if (!(storeId && folderId && boardId))
            throw `${tag} is need ${getVariableName(
              storeId,
              'storeId'
            )} ${getVariableName(folderId, 'folderId')} ${getVariableName(
              boardId,
              'boardId'
            )}`

          await handleBoard(folderId, boardId)
          break
        case 'BARCODE_DAILY_ALARM':
          if (!storeId) throw `${tag} is need storeId`

          push('/shelfLife')
          break
        case 'BARCODE_TIMELY_ALARM':
          if (!(storeId && inventoryId))
            throw `${tag} is need ${getVariableName(
              storeId,
              'storeId'
            )} ${getVariableName(inventoryId, 'inventoryId')}`
          await handleBarcodeTimelyAlarm(inventoryId)
          break
        case 'RECEIVE_APPLY':
        case 'RECEIVE_SUGGEST':
        case 'FIRST_PASS':
        case 'FIRST_FAIL':
        case 'FIRST_ACCEPT':
        case 'FIRST_DENY':
        case 'LAST_PASS':
        case 'LAST_FAIL':
        case 'LAST_ACCEPT':
        case 'LAST_DENY':
        case 'CHAT_MESSAGE':
          if (!chatRoomId) throw `${tag} is need chatRoomId`
          push(`/chat/${chatRoomId}`)
          break
        case 'RECOMMEND_RESUME':
          push('/todayRecommend')
          break
        case 'RECOMMEND_RECRUIT':
          push('/todayRecommend')
          break
        case 'BOARD_DIRECT':
          notificationTempSave({
            tabs: 'NOTICE',
          })
          push('/notification')
          break
        default:
          throw `${tag} is invalid push tag`
      }
    } catch (error) {
      const err = error as Error
      alertDialog.open({
        title: '通知が有効ではありません',
        description: err?.message ?? '',
        actionButton: {
          variant: 'fill',
          semantic: 'primary',
          label: '確認',
          onClick: () => alertDialog.close(),
        },
      })
      Sentry.captureException(err ?? new Error('useNotification exception'), {
        extra: {
          ...args,
          timestamp: new Date().toISOString(),
          asPath,
          userAgent: window.navigator.userAgent,
        },
      })
    } finally {
      setProcessing(false)
    }
  }

  const handle = async (args: NotificationPayload) => {
    if (window.location.pathname === '/') {
      callRouteComplete(() => processNotification(args))
    } else {
      processNotification(args)
    }
  }

  return {
    needLogin: true,
    eventName: 'notification',
    handle,
    processing,
  }
}
