import { putStore, PutStoreParams, PutStoreReq } from 'lib/api/store/putStore'
import { useMutation, useQueryClient } from 'react-query'
import { createStoreListKey } from './useStoreListQuery'

export default function usePutStoreMutation() {
  const queryClient = useQueryClient()

  return useMutation((value: PutStoreParams & PutStoreReq) => putStore(value), {
    onSuccess: async () => {
      const storeListKey = createStoreListKey()

      await queryClient.invalidateQueries(storeListKey)
    },
  })
}
