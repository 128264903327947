import { putAccessToken } from 'lib/api/auth/putAccessToken'
import { AxiosRequestConfig } from 'axios'

import { isTokenExpired } from 'lib/utils/common/isTokenExpired'
import { HXAP } from 'lib/bridge'
import { JwtTokenType } from 'lib/api/types'
import { postLogout } from '../../api/auth/postLogout'
import { decodeAccessToken } from '../common/decodeAccessToken'
import { setLoginState } from 'lib/utils/login/loginState'

async function logout() {
  if (typeof window === 'undefined') return

  try {
    const tokens = await HXAP.loadData<JwtTokenType>('jwt')
    if (!!tokens) {
      await HXAP.removeData('jwt')
      setLoginState(false)
      window.location.href = `${window.location.origin}/auth/welcome`
      setTimeout(() => {
        postLogout()
      })
    }
  } catch (error) {
    console.warn(error)
  }
}

export async function clientSideInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  let tokens = await HXAP.loadData<JwtTokenType>('jwt', {
    accessToken: '',
    refreshToken: '',
  })

  const pushToken = await HXAP.getPushToken()
  if (isTokenExpired(tokens.refreshToken)) {
    logout()
    return config
  }

  if (isTokenExpired(tokens.accessToken)) {
    const decodedToken = decodeAccessToken(tokens.accessToken)
    const storeId = decodedToken.userInfo.storeId

    try {
      const newAccessToken = await putAccessToken({
        refresh_token: tokens.refreshToken,
        push_id: pushToken ?? undefined,
        store_id: storeId,
        userOnlyYn: storeId === undefined ? 'Y' : 'N',
      })
      tokens.accessToken = newAccessToken.accessToken
      await HXAP.saveData<JwtTokenType>('jwt', tokens)
    } catch {
      logout()
      return config
    }
  }
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${tokens.accessToken}`,
  }

  return config
}
