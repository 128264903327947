import { useEffect, useState } from 'react'
import { getLoginState } from 'lib/utils/login/loginState'

interface ReturningUseLoginState {
  isLogin: boolean
}

export function useLoginState(): ReturningUseLoginState {
  const [isLogin, setLogin] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const authState = getLoginState()
    setLogin(authState ?? false)

    const handleLoginState = (event: CustomEvent<{ loginState: boolean }>) => {
      setLogin(event.detail.loginState)
    }

    window.addEventListener('loginState', handleLoginState)

    return () => {
      window.removeEventListener('loginState', handleLoginState)
    }
  }, [])

  return {
    isLogin: isLogin,
  }
}
