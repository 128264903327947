import * as RadixToast from '@radix-ui/react-toast'
import { keyframes, styled } from '@higherx/stitches-config'
import { Icon } from '@higherx/hds-icon'
import Text from 'components/v2/common/Text'
import { IconButton } from './IconButton'
import { Button } from './Button'

export const ToastPrimitive = RadixToast

export type ToastCustomProps = {
  title?: string | React.ReactNode
  description: string | React.ReactNode
  leftAccessary?: 'positive' | 'negative' | 'informative'
  actionLabel?: string
  onActionClick?: () => void
}

export type ToastProps = ToastCustomProps &
  Omit<React.ComponentProps<typeof RadixToast.Root>, 'title'>

export function Toast({
  title,
  description,
  leftAccessary,
  actionLabel,
  onActionClick,
  ...props
}: ToastProps) {
  return (
    <>
      <ToastRoot
        hasLeftAccessary={!!leftAccessary}
        hasTitle={!!title}
        hasDescription
        duration={3000}
        {...props}
      >
        {leftAccessary && (
          <LeftAccessary>
            <Icon
              name={
                leftAccessary === 'informative'
                  ? 'LineInterfaceInformationCircle'
                  : leftAccessary === 'positive'
                  ? 'FillInterfaceCheckCircle1'
                  : 'FillInterfaceAttentionCircle'
              }
              color={
                leftAccessary === 'informative'
                  ? '$system_content_inverse-secondary'
                  : leftAccessary === 'positive'
                  ? '$system_semantic_positive_middle'
                  : '$system_semantic_negative_middle'
              }
            />
          </LeftAccessary>
        )}
        {title && (
          <Title asChild>
            <Text
              variant='bodyLarge'
              weight='bold'
              color='$system_content_inverse-primary'
            >
              {title}
            </Text>
          </Title>
        )}
        <Description asChild>
          <Text variant='bodyMedium' color='$system_content_inverse-primary'>
            {description}
          </Text>
        </Description>

        {actionLabel ? (
          <Action asChild altText='toast action'>
            <Button
              variant='text'
              semantic='secondary'
              size='small'
              onClick={onActionClick}
            >
              {actionLabel}
            </Button>
          </Action>
        ) : (
          <Close aria-label='Close'>
            <IconButton
              iconName='FillInterfaceCloseCircle1'
              size='small'
              color='$system_content_tertiary'
            />
          </Close>
        )}
      </ToastRoot>
      <ToastViewport />
    </>
  )
}

const VIEWPORT_PADDING_HORIZONTAL = 16
const VIEWPORT_PADDING_VERTICAL = 32

const ToastViewport = styled(RadixToast.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 16,
  paddingLeft: VIEWPORT_PADDING_HORIZONTAL,
  paddingRight: VIEWPORT_PADDING_HORIZONTAL,
  paddingBottom: VIEWPORT_PADDING_VERTICAL,
  width: '100%',
  margin: 0,
  listStyle: 'none',
  zIndex: 10000002,
  outline: 'none',
})

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

const slideIn = keyframes({
  from: {
    transform: `translateY(calc(100% + ${VIEWPORT_PADDING_VERTICAL}px))`,
  },
  to: { transform: 'translateY(0)' },
})

const swipeOut = keyframes({
  from: { transform: 'translateY(var(--radix-toast-swipe-end-y))' },
  to: {
    transform: `translateY(calc(100% + ${VIEWPORT_PADDING_VERTICAL}px))`,
  },
})

const ToastRoot = styled(RadixToast.Root, {
  backgroundColor: '$system_background_inverse-primary',
  borderRadius: 12,
  padding: 16,
  display: 'grid',
  gridTemplateColumns: 'auto max-content',
  columnGap: 12,
  alignItems: 'center',

  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`,
  },
  '&[data-swipe="move"]': {
    transform: 'translateY(var(--radix-toast-swipe-move-y))',
  },
  '&[data-swipe="cancel"]': {
    transform: 'translateY(0)',
    transition: 'transform 200ms ease-out',
  },
  '&[data-swipe="end"]': {
    animation: `${swipeOut} 100ms ease-out`,
  },

  variants: {
    hasLeftAccessary: {
      true: {
        gridTemplateColumns: 'auto 1fr auto',
      },
    },
    hasTitle: {
      true: {
        gridTemplateAreas: '"title action"',
      },
    },
    hasDescription: {
      true: {
        gridTemplateAreas: '"description action"',
      },
    },
  },
  compoundVariants: [
    {
      hasLeftAccessary: true,
      hasTitle: true,
      css: {
        gridTemplateAreas: '"leftAccessary title action"',
      },
    },
    {
      hasLeftAccessary: true,
      hasDescription: true,
      css: {
        gridTemplateAreas: '"leftAccessary description action"',
      },
    },
    {
      hasTitle: true,
      hasDescription: true,
      css: {
        gridTemplateAreas: '"title action" "description action"',
      },
    },
    {
      hasLeftAccessary: true,
      hasTitle: true,
      hasDescription: true,
      css: {
        gridTemplateAreas:
          '"leftAccessary title action" "leftAccessary description action"',
      },
    },
  ],
})

const LeftAccessary = styled('div', {
  gridArea: 'leftAccessary',
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Title = styled(RadixToast.Title, {
  gridArea: 'title',
  margin: 0,
  marginBottom: 4,
  color: '$system_content_inverse-primary',
  whiteSpace: 'pre-line',
  alignSelf: 'flex-start',
})

const Description = styled(RadixToast.Description, {
  gridArea: 'description',
  margin: 0,
  color: '$system_content_inverse-primary',
  whiteSpace: 'pre-line',
})

const Action = styled(RadixToast.Action, {
  gridArea: 'action',
})

const Close = styled(RadixToast.Close, {
  gridArea: 'action',
})
