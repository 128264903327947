import { Router } from 'next/router'
import { useEffect } from 'react'

const ENV = process.env.NEXT_PUBLIC_ENV
const GA_CODE = String(process.env.NEXT_PUBLIC_GA_CODE)

export function useGTag() {
  useEffect(() => {
    if (ENV !== 'production') return

    const handleRouteChange = (url: URL) => {
      window.gtag('config', GA_CODE, {
        page_path: url,
      })
    }

    Router.events.on('routeChangeComplete', handleRouteChange)
    Router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
      Router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [])
}
