import React from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonProps } from 'components/v2/designSystem/Button'

export type AlertDialogParamType = {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  cancelButton?: Omit<ButtonProps, 'size' | 'loading' | 'defaultValue'> & {
    label: string
  }
  actionButton: Omit<ButtonProps, 'size' | 'loading' | 'defaultValue'> & {
    label: string
  }
}

export type GlobalAlertDialogStateType = {
  open: boolean
  loading?: boolean
} & AlertDialogParamType

const initialState: GlobalAlertDialogStateType = {
  open: false,
  loading: false,
  title: '',
  description: '',
  cancelButton: undefined,
  actionButton: { variant: 'fill', semantic: 'negative', label: '' },
}

export const globalAlertDialogStateSlice = createSlice({
  name: 'globalAlertDialogState',
  initialState,
  reducers: {
    openAlertDialog: (state, action: PayloadAction<AlertDialogParamType>) => {
      state.open = true
      state.title = action.payload.title
      state.description = action.payload.description
      state.cancelButton = action.payload.cancelButton
      state.actionButton = action.payload.actionButton
    },
    closeAlertDialog: state => {
      Object.assign(state, initialState)
    },
    loadingAlertDialog: state => {
      state.loading = true
    },
  },
})

export const {
  openAlertDialog,
  closeAlertDialog,
  loadingAlertDialog,
} = globalAlertDialogStateSlice.actions

export default globalAlertDialogStateSlice.reducer
