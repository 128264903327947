import { callNative } from '../core/callNative'

/**
 * ## kakaoMessage
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 카카오톡 메시지 공유
 */
export async function kakaoMessage(
  text: string,
  url: string,
  buttonTitle: string
): Promise<void> {
  await callNative(
    'kakaoMessage',
    {
      text,
      url,
      buttonTitle,
    },
    false
  )
}
