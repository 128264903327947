import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const COOKIE_HXAT = '__hxat'

export function setHXATCookie(token: string): void {
  cookies.set(COOKIE_HXAT, token, {
    secure: false,
    httpOnly: false,
    sameSite: 'strict',
  })
}
