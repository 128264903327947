import { callNative } from '../core/callNative'

/**
 * ## openPdf
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * Pdf 화면을 띄웁니다.
 */
export async function openPdf(url: string): Promise<void> {
  await callNative(
    'openPdf',
    {
      url,
    },
    false
  )
}
