import { globalCss } from '@higherx/stitches-config'

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
  },

  body: {
    margin: 0,
  },

  'input:focus': {
    outline: 'none',
  },

  'button, a, img, textarea, input, label': {
    '-webkit-tap-highlight-color': 'transparent',
  },

  button: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    border: 'none',
    color: 'unset',
  },
})
