import { BaseText as HXText, BaseTextProps } from '@higherx/hds-core'
import { styled } from '@higherx/stitches-config'

export default function Text({ children, ...props }: BaseTextProps) {
  return <StyledText {...props}>{children}</StyledText>
}

const StyledText = styled(HXText, {
  wordBreak: 'break-all',
})
