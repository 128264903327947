import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ToastCustomProps } from 'components/v2/designSystem/Toast'

export type GlobalToastStateType = {
  open: boolean
} & ToastCustomProps

const initialState: GlobalToastStateType = {
  open: false,
  leftAccessary: undefined,
  title: undefined,
  description: '',
  actionLabel: undefined,
  onActionClick: undefined,
}

export const globalToastStateSlice = createSlice({
  name: 'globalToastState',
  initialState,
  reducers: {
    openToast: (state, action: PayloadAction<ToastCustomProps>) => {
      state.open = true
      state.leftAccessary = action.payload.leftAccessary
      state.title = action.payload.title
      state.description = action.payload.description
      state.actionLabel = action.payload.actionLabel
      state.onActionClick = action.payload.onActionClick
    },
    closeToast: state => {
      Object.assign(state, initialState)
    },
  },
})

export const { openToast, closeToast } = globalToastStateSlice.actions

export default globalToastStateSlice.reducer
