import Script from 'next/script'
import { useGTag } from './useGTag'

const ENV = process.env.NEXT_PUBLIC_ENV
const GA_CODE = process.env.NEXT_PUBLIC_GA_CODE

export function GoogleAnalytics() {
  useGTag()

  if (ENV !== 'production') {
    return null
  }

  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          window.gtag = gtag
          gtag('js', new Date());
          gtag('config', '${GA_CODE}', {
            page_path: window.location.pathname
          });
        `,
        }}
      />
      <Script
        id='gtag-manager'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer' ? '&l='+l : '';
                j.async=true;
            j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TGLTNLT');
        `,
        }}
      ></Script>
    </>
  )
}
