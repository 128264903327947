import { callNative } from '../core/callNative'

/**
 * ## getAppVersion
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 앱의 version 값을 가져옵니다.
 *
 * @returns {string} 0.0.0
 */
export async function getAppVersion(): Promise<string> {
  const version = await callNative('getAppVersion')
  return version as string
}
