import { callNative } from '../core/callNative'
import { OpenGalleryArgs, NativeImage } from './types'

/**
 * ## openGallery
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * presigned url을 이용한 파일 업로드를 수행하는 갤러리 화면을 엽니다.
 *
 * @returns
 * * id : 파일 id
 * * previewUrl : 미리보기 이미지 url
 */
export async function openGallery(
  payload: OpenGalleryArgs
): Promise<NativeImage[] | null> {
  const data = await callNative('openGallery', payload, false)
  return data as NativeImage[]
}
