import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TempPageDataType = {
  value: string
}

const initialState: TempPageDataType = {
  value: '',
}

const tempPageData = createSlice({
  name: 'tempPageData',
  initialState,
  reducers: {
    setTempPageData: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
})

export const { setTempPageData } = tempPageData.actions

export default tempPageData.reducer
