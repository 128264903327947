import * as Stitches from '@stitches/react'
import { styled, keyframes, config } from '@higherx/stitches-config'
import Text from 'components/v2/common/Text'
import { forwardRef } from 'react'
import { Icon, IconNameType } from '@higherx/hds-icon'

type ButtonVariantsType = Pick<
  Required<Stitches.VariantProps<typeof StyledButton>>,
  'variant' | 'size' | 'semantic'
>

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  ButtonVariantsType & {
    type?: 'submit' | 'reset' | 'button'
    disabled?: boolean
    loading?: boolean
    round?: boolean
    leftIconName?: IconNameType
    rightIconName?: IconNameType
    width?: number | string
    fullWidth?: boolean
    css?: Stitches.CSS<typeof config>
    style?: React.CSSProperties
  }

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      variant,
      semantic,
      size,
      children,
      type = 'button',
      disabled,
      loading,
      leftIconName,
      rightIconName,
      width,
      fullWidth,
      css,
      ...props
    },
    ref
  ) {
    const textVariant =
      size === 'large'
        ? 'labelLarge'
        : size === 'medium'
        ? 'labelMedium'
        : size === 'small'
        ? 'labelSmall'
        : 'labelXSmall'

    const textColor =
      variant === 'fill'
        ? semantic === 'primary'
          ? '$component_button_fill_primary_label'
          : semantic === 'secondary'
          ? '$component_button_fill_secondary_label'
          : '$component_button_fill_negative_label'
        : variant === 'line'
        ? semantic === 'primary'
          ? '$component_button_line_primary_label'
          : semantic === 'secondary'
          ? '$component_button_line_secondary_label'
          : semantic === 'neutral'
          ? '$component_button_line_neutral_label'
          : '$component_button_line_negative_label'
        : semantic === 'primary'
        ? '$component_button_text_primary_label'
        : semantic === 'secondary'
        ? '$component_button_text_secondary_label'
        : '$component_button_text_negative_label'

    const iconSize =
      size === 'large' || size === 'medium' ? 'standard' : 'small'

    const iconColor =
      variant === 'fill'
        ? semantic === 'primary'
          ? '$component_button_fill_primary_icon'
          : semantic === 'secondary'
          ? '$component_button_fill_secondary_icon'
          : '$component_button_fill_negative_icon'
        : variant === 'line'
        ? semantic === 'primary'
          ? '$component_button_line_primary_icon'
          : semantic === 'secondary'
          ? '$component_button_line_secondary_icon'
          : semantic === 'neutral'
          ? '$component_button_line_neutral_icon'
          : '$component_button_line_negative_icon'
        : semantic === 'primary'
        ? '$component_button_text_primary_icon'
        : semantic === 'secondary'
        ? '$component_button_text_secondary_icon'
        : '$component_button_text_negative_icon'

    return (
      <StyledButton
        variant={variant}
        semantic={semantic}
        size={size}
        type={type}
        disabled={disabled || loading}
        loading={loading}
        hasLeftIcon={!!leftIconName}
        hasRightIcon={!!rightIconName}
        css={
          !!width || fullWidth
            ? {
                ...css,
                width: fullWidth ? '100%' : width,
              }
            : {
                ...css,
              }
        }
        {...props}
        ref={ref}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {leftIconName && (
              <StyledIcon
                name={leftIconName}
                size={iconSize}
                color={iconColor}
              />
            )}
            <Text
              variant={textVariant}
              weight='medium'
              color={textColor}
              css={{ whiteSpace: 'nowrap' }}
            >
              {children}
            </Text>
            {rightIconName && (
              <StyledIcon
                name={rightIconName}
                size={iconSize}
                color={iconColor}
              />
            )}
          </>
        )}

        <ActiveEffect />
      </StyledButton>
    )
  }
)

const StyledIcon = styled(Icon, {})

const spin = keyframes({
  '0%': {
    '-webkit-transform': 'rotate(0deg)',
    transform: 'rotate(0deg)',
  },
  '100%': {
    '-webkit-transform': 'rotate(360deg)',
    transform: 'rotate(360deg)',
  },
})

const LoadingSpinner = styled('span', {
  borderStyle: 'solid',
  borderRadius: '$ref_border-radius_circle',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: `${spin} 1s linear infinite`,
})

const ActiveEffect = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  opacity: 0.3,
  position: 'absolute',
  top: 0,
  left: 0,
})

const StyledButton = styled('button', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  overflow: 'hidden',

  '&:disabled': {
    opacity: 0.3,
  },

  '&:active': {
    [`& ${ActiveEffect}`]: {
      backgroundColor: '$system_state_pressed',
    },
  },

  variants: {
    variant: {
      fill: {},
      line: {
        borderWidth: '$component_button_line_border-width',
        borderStyle: 'solid',
      },
      text: {},
    },
    semantic: {
      primary: {},
      secondary: {},
      neutral: {},
      negative: {},
    },
    hasLeftIcon: {
      true: {},
    },
    hasRightIcon: {
      true: {},
    },
    size: {
      large: {
        height: '$component_button_large_height',
        borderRadius: '$component_button_square_large_border-radius',
        paddingLeft: '$component_button_large_padding-horizontal',
        paddingRight: '$component_button_large_padding-horizontal',

        [`& ${LoadingSpinner}`]: {
          width: 20,
          height: 20,
          borderWidth: 2,
        },
      },
      medium: {
        height: '$component_button_medium_height',
        borderRadius: '$component_button_square_medium_border-radius',
        paddingLeft: '$component_button_medium_padding-horizontal',
        paddingRight: '$component_button_medium_padding-horizontal',

        [`& ${LoadingSpinner}`]: {
          width: 20,
          height: 20,
          borderWidth: 2,
        },
      },
      small: {
        height: '$component_button_small_height',
        borderRadius: '$component_button_square_small_border-radius',
        paddingLeft: '$component_button_small_padding-horizontal',
        paddingRight: '$component_button_small_padding-horizontal',

        [`& ${LoadingSpinner}`]: {
          width: 16,
          height: 16,
          borderWidth: 1.5,
        },
      },
      xSmall: {
        height: '$component_button_x-small_height',
        borderRadius: '$component_button_square_x-small_border-radius',
        paddingLeft: '$component_button_x-small_padding-horizontal',
        paddingRight: '$component_button_x-small_padding-horizontal',

        [`& ${LoadingSpinner}`]: {
          width: 16,
          height: 16,
          borderWidth: 1.5,
        },
      },
    },
    round: {
      true: {},
    },
    loading: {
      true: {
        '&:disabled': {
          opacity: 1,
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: 'fill',
      semantic: 'primary',
      css: {
        backgroundColor: '$component_button_fill_primary_background',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_fill_primary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'fill',
      semantic: 'secondary',
      css: {
        backgroundColor: '$component_button_fill_secondary_background',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_fill_secondary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'fill',
      semantic: 'negative',
      css: {
        backgroundColor: '$component_button_fill_negative_background',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_fill_negative_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'line',
      semantic: 'primary',
      css: {
        borderColor: '$component_button_line_primary_border',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_line_primary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'line',
      semantic: 'secondary',
      css: {
        borderColor: '$component_button_line_secondary_border',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_line_secondary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'line',
      semantic: 'neutral',
      css: {
        borderColor: '$component_button_line_neutral_border',
        backgroundColor: '$component_button_line_neutral_background',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_line_neutral_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'line',
      semantic: 'negative',
      css: {
        borderColor: '$component_button_line_negative_border',

        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_line_negative_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'text',
      css: {
        padding: 0,
      },
    },
    {
      variant: 'text',
      semantic: 'primary',
      css: {
        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_text_primary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'text',
      semantic: 'secondary',
      css: {
        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_text_secondary_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      variant: 'text',
      semantic: 'negative',
      css: {
        [`& ${LoadingSpinner}`]: {
          borderColor: '$component_button_text_negative_icon',
          borderBottomColor: 'transparent',
        },
      },
    },
    {
      size: 'large',
      hasLeftIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginRight: '$component_button_large_between-icon-label',
        },
      },
    },
    {
      size: 'large',
      hasRightIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginLeft: '$component_button_large_between-icon-label',
        },
      },
    },
    {
      size: 'medium',
      hasLeftIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginRight: '$component_button_medium_between-icon-label',
        },
      },
    },
    {
      size: 'medium',
      hasRightIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginLeft: '$component_button_medium_between-icon-label',
        },
      },
    },
    {
      size: 'small',
      hasLeftIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginRight: '$component_button_small_between-icon-label',
        },
      },
    },
    {
      size: 'small',
      hasRightIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginLeft: '$component_button_small_between-icon-label',
        },
      },
    },
    {
      size: 'xSmall',
      hasLeftIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginRight: '$component_button_x-small_between-icon-label',
        },
      },
    },
    {
      size: 'xSmall',
      hasRightIcon: true,
      css: {
        [`& ${StyledIcon}`]: {
          marginLeft: '$component_button_x-small_between-icon-label',
        },
      },
    },
    {
      size: 'large',
      round: true,
      css: {
        borderRadius: '$component_button_round_border-radius',
      },
    },
    {
      size: 'medium',
      round: true,
      css: {
        borderRadius: '$component_button_round_border-radius',
      },
    },
    {
      size: 'small',
      round: true,
      css: {
        borderRadius: '$component_button_round_border-radius',
      },
    },
    {
      size: 'xSmall',
      round: true,
      css: {
        borderRadius: '$component_button_round_border-radius',
      },
    },
  ],
})
