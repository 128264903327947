import { callNative } from '../core/callNative'
import {
  DeviceCheckInfo,
  OpenCheckModifyCameraArgs,
  OpenCheckWriteCameraArgs,
} from './types'

/**
 * ## openCheckCamera
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 업무 체크를 위한 카메라 UI를 엽니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 null
 */
export async function openCheckCamera(
  payload: OpenCheckModifyCameraArgs
): Promise<DeviceCheckInfo>

/**
 * ## openCheckCamera
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * 업무 수정을 위한 카메라 UI를 엽니다.
 *
 * @returns {(T | null)} 저장된 값, 없을 경우 null
 */
export async function openCheckCamera(
  payload: OpenCheckWriteCameraArgs
): Promise<DeviceCheckInfo>

export async function openCheckCamera(
  payload: OpenCheckWriteCameraArgs | OpenCheckModifyCameraArgs
): Promise<DeviceCheckInfo> {
  let data = await callNative('openCheckCamera', payload, false)
  if (typeof data === 'string') {
    data = JSON.parse(data)
  }
  return data as DeviceCheckInfo
}
