import { safeSessionStorage } from '../storage'

const LOGIN_STATE_KEY = '__ls'

export function getLoginState(): boolean {
  if (typeof window === 'undefined') return false

  const value = safeSessionStorage.get(LOGIN_STATE_KEY)
  return value === 'true'
}

export function setLoginState(state: boolean): void {
  if (typeof window === 'undefined') return

  safeSessionStorage.set(LOGIN_STATE_KEY, `${state}`)

  window.dispatchEvent(
    new CustomEvent<{ loginState: boolean }>('loginState', {
      detail: {
        loginState: state,
      },
    })
  )
}
