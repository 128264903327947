import { callNative } from '../core/callNative'
import { PassCertificationInfo } from './types'

/**
 * ## openPASS
 *
 * @supports
 * * Android
 * * iOS
 *
 * @description
 * PASS 인증 뷰를 띄웁니다.
 */
export async function openPASS(): Promise<PassCertificationInfo | null> {
  const result = await callNative('openPASS', undefined, false)
  return result as PassCertificationInfo
}
