import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'

import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { styled } from '@higherx/stitches-config'

import wrapper, { persistor } from '../store'

import { globalStyles } from 'styles/globalStyles'
import 'styles/index.css'

import { GoogleAnalytics } from 'components/v2/common/GoogleAnalytics'
import LoadingSpinner from 'components/v2/common/LoadingSpinner'

import { Toast, ToastPrimitive } from 'components/v2/designSystem/Toast'
import { AlertDialog } from 'components/v2/designSystem/AlertDialog'
import { SystemAlert } from 'components/v2/common/SystemAlert'

import useAlertDialog from 'hooks/useAlertDialog'
import useToast from 'hooks/useToast'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import { useSpinnerRouteEvent } from 'hooks/app/useSpinnerRouteEvent'
import { useScrollAutoRestore } from 'hooks/app/useScrollAutoRestore'

import { HXAP, HigherXAppProtocol } from 'lib/bridge'
import { useHardwareBack } from 'lib/bridge/event/useHardwareBack'

// Legacy
import GsStoreCodeGuideBottomSheet from 'components/v2/common/GsStoreCodeGuideBottomSheet'
import { useRouter } from 'next/router'
import {
  popOverlayStack,
  setIsTriggedBackHandler,
} from 'store/modules/backState'

function MyApp({ Component, pageProps, router }: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // staleTime: 1000 * 60 * 5,
          },
        },
      })
  )
  const { store, props } = wrapper.useWrappedStore(pageProps)

  const { isPageLoading } = useSpinnerRouteEvent(router)
  const { ref } = useScrollAutoRestore(router)

  const isNoticeBottomSheetShow =
    new Date().getTime() <= new Date('2023-10-31 23:59:00').getTime()

  globalStyles()

  return (
    <>
      <GoogleAnalytics />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReduxProvider store={store}>
            <ToastPrimitive.Provider swipeDirection='down'>
              <PersistGate persistor={persistor} loading={null}>
                <SystemAlert />
                <HigherXAppProtocol />
                {/* 일반 bottom sheet 공지 */}
                <GsStoreCodeGuideBottomSheet />
                {/* 일본용 앱에서는 알바추천 공지 노출 X */}
                {/* {isNoticeBottomSheetShow && <CommonNoticeBottomSheet />} */}
                {isPageLoading && <LoadingSpinner />}

                <Container id='main_container' ref={ref}>
                  <Component {...props} />

                  <AlertDialogWrapper />
                  <ToastWrapper />
                  <BackControlWrapper />
                </Container>
              </PersistGate>
            </ToastPrimitive.Provider>
          </ReduxProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

const Container = styled('div', {
  height: '100vh',
  overflow: 'scroll',
})

function AlertDialogWrapper() {
  const { open, title, description, cancelButton, actionButton, loading } =
    useAppSelector(state => state.globalAlertDialogState)

  const alertDialog = useAlertDialog()

  return (
    <AlertDialog
      open={open}
      onOpenChange={open => {
        if (!open) alertDialog.close()
      }}
    >
      <AlertDialog.Content>
        <AlertDialog.Title>{title}</AlertDialog.Title>
        <AlertDialog.Description>{description}</AlertDialog.Description>
        <AlertDialog.Buttons>
          {cancelButton && (
            <AlertDialog.CancelButton
              {...cancelButton}
              disabled={loading}
              size='large'
            >
              {cancelButton?.label}
            </AlertDialog.CancelButton>
          )}

          <AlertDialog.ActionButton
            {...actionButton}
            size='large'
            loading={loading}
          >
            {actionButton.label}
          </AlertDialog.ActionButton>
        </AlertDialog.Buttons>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

function ToastWrapper() {
  const { title, description, leftAccessary, actionLabel, onActionClick } =
    useAppSelector(state => state.globalToastState)
  const { overlayStack } = useAppSelector(state => state.backState)

  const toast = useToast()

  return (
    <Toast
      open={!!overlayStack.includes('toast')}
      onOpenChange={open => {
        if (!open) toast.close()
      }}
      leftAccessary={leftAccessary}
      title={title}
      description={description}
      actionLabel={actionLabel}
      onActionClick={onActionClick}
    />
  )
}

function BackControlWrapper() {
  const { back, pathname } = useRouter()

  const dispatch = useAppDispatch()
  const { overlayStack, hasCustomBackHandler, isBackEventBlock } =
    useAppSelector(state => state.backState)

  useHardwareBack(() => {
    if (isBackEventBlock) return

    if (overlayStack.length === 0) {
      if (hasCustomBackHandler) {
        dispatch(setIsTriggedBackHandler(true))
      } else {
        if (
          pathname === '/todayChecklist' ||
          pathname === '/shelfLife' ||
          pathname === '/todayRecommend' ||
          pathname === '/more' ||
          pathname === '/login'
        ) {
          HXAP.exitApp()
        } else {
          back()
        }
      }
    } else {
      dispatch(popOverlayStack())
    }
  })

  return null
}

export default MyApp
