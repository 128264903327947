import React, { isValidElement, useContext, useEffect, useRef } from 'react'
import * as RadixDialog from '@radix-ui/react-dialog'
import * as Tabs from '@radix-ui/react-tabs'

import BottomSheetProvider, { BottomSheetContext } from './BottomSheetProvider'
import { keyframes, styled } from '@higherx/stitches-config'
import Text from 'components/v2/common/Text'
import { DEFAULT_BOTTOM_SHEET_STACK, FULL_HEIGHT } from './value'
import { IconButton } from '../IconButton'
import { useUniqueId } from 'hooks/useUniqueId'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import {
  deleteOverlayStack,
  initPopedOverlayStack,
  pushOverlayStack,
} from 'store/modules/backState'

export type BottomSheetProps = React.ComponentProps<typeof RadixDialog.Root> & {
  height: 'full' | 'fitContent'
  preventContentDrag?: boolean
}

const BottomSheetTrigger = RadixDialog.Trigger

const BottomSheetClose = RadixDialog.Close

function BottomSheetTitle({ children }: { children: React.ReactNode }) {
  return (
    <BottomSheetTitleWrapper>
      <RadixDialog.Title asChild>{children}</RadixDialog.Title>
    </BottomSheetTitleWrapper>
  )
}

function BottomSheetDescription({ children }: { children: React.ReactNode }) {
  return (
    <BottomSheetDescriptionWrapper>
      <RadixDialog.Description asChild>{children}</RadixDialog.Description>
    </BottomSheetDescriptionWrapper>
  )
}

function BottomSheetHeader({ children }: { children?: React.ReactNode }) {
  const {
    bottomSheetStack,
    popBottomSheetStack,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useContext(BottomSheetContext)

  return (
    <>
      <BottomSheetHeaderFixed>
        <BottomSheetHeaderContainer
          onTouchStart={e => handleTouchStart(e)}
          onTouchMove={e => handleTouchMove(e)}
          onTouchEnd={e => handleTouchEnd(e)}
        >
          <HeaderTitleContainer>
            <Text variant='titleSmall'>{children}</Text>
          </HeaderTitleContainer>

          {bottomSheetStack[bottomSheetStack.length - 1] ===
          DEFAULT_BOTTOM_SHEET_STACK ? (
            <RadixDialog.Close asChild>
              <IconButton iconName='LineInterfaceClose' size='medium' />
            </RadixDialog.Close>
          ) : (
            <IconButton
              iconName='LineArrowArrowNavigation'
              size='medium'
              onClick={() => {
                popBottomSheetStack()
              }}
            />
          )}
        </BottomSheetHeaderContainer>
      </BottomSheetHeaderFixed>
      <div style={{ height: 48 }}></div>
    </>
  )
}

function BottomSheetContent({
  children,
  headerTitle,
}: {
  children: React.ReactNode
  headerTitle?: string
}) {
  return (
    <TabsContent value={DEFAULT_BOTTOM_SHEET_STACK}>
      <Tabs.List>
        <BottomSheetHeader>{headerTitle}</BottomSheetHeader>
        {children}
      </Tabs.List>
    </TabsContent>
  )
}

function BottomSheetTabsContent({
  children,
  headerTitle,
  ...props
}: React.ComponentProps<typeof Tabs.Content> & { headerTitle?: string }) {
  return (
    <TabsContent
      {...props}
      css={{
        height: '100%',
      }}
    >
      <Tabs.List>
        <BottomSheetHeader>{headerTitle}</BottomSheetHeader>
        {children}
      </Tabs.List>
    </TabsContent>
  )
}

function BottomSheetTabsBackTrigger({
  children,
}: {
  children: React.ReactNode
}) {
  const { popBottomSheetStack } = useContext(BottomSheetContext)

  return <div onClick={popBottomSheetStack}>{children}</div>
}

const BottomSheetTabsTrigger = Tabs.Trigger

const BottomSheetTriggerType = (<BottomSheetTrigger />).type

function getBottomSheetTrigger(children: React.ReactNode) {
  const childrenArray = React.Children.toArray(children)
  return childrenArray.filter(
    child => isValidElement(child) && child.type === BottomSheetTriggerType
  )
}

function getChildrenExceptTrigger(children: React.ReactNode) {
  const childrenArray = React.Children.toArray(children)
  return childrenArray.filter(
    child => isValidElement(child) && child.type !== BottomSheetTriggerType
  )
}

// BottomSheet의 컨텐츠 슬라이드 애니메이션을 맨 처음에는 동작안하도록 하기 위해 선언
let initialOpen = true

function BottomSheetMain({
  height,
  children,
  open: openProps,
  onOpenChange,
  preventContentDrag,
  ...props
}: BottomSheetProps) {
  const uniqueId = useUniqueId('bottomSheet')

  const dispatch = useAppDispatch()
  const { overlayStack, popedOverlayStack } = useAppSelector(
    state => state.backState
  )

  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const bottomSheetTrigger = getBottomSheetTrigger(children)
  const childrenExceptTrigger = getChildrenExceptTrigger(children)

  const {
    sheetRef,
    bottomSheetStack,
    pushBottomSheetStack,
    initBottomSheetStack,
    backToPosition,
    closeDetect,
    setCloseDetect,
    positionY,
    setPositionY,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useContext(BottomSheetContext)

  useEffect(() => {
    if (closeDetect && sheetRef.current) {
      setPositionY(sheetRef.current.clientHeight + 48)
      setTimeout(() => {
        closeButtonRef.current?.click()

        setCloseDetect(false)
        setPositionY(0)
      }, 100)
    }
  }, [
    closeDetect,
    setPositionY,
    sheetRef,
    initBottomSheetStack,
    setCloseDetect,
  ])

  useEffect(() => {
    if (initialOpen && bottomSheetStack.length > 1) {
      initialOpen = false
    }
  }, [bottomSheetStack])

  useEffect(() => {
    if (popedOverlayStack === uniqueId) {
      closeButtonRef.current?.click()
      dispatch(initPopedOverlayStack())
    }
  }, [dispatch, popedOverlayStack, uniqueId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.defaultOpen) {
        dispatch(pushOverlayStack(uniqueId))
      }
    }, 1)

    return () => clearTimeout(timeout)
  }, [dispatch, props.defaultOpen, uniqueId])

  useEffect(() => {
    if (openProps) {
      dispatch(pushOverlayStack(uniqueId))
    }
  }, [openProps, dispatch, uniqueId])

  return (
    <BottomSheetProvider>
      <RadixDialog.Root
        open={openProps}
        onOpenChange={open => {
          if (onOpenChange) onOpenChange(open)

          if (!open) {
            initBottomSheetStack()

            if (overlayStack.includes(uniqueId)) {
              dispatch(deleteOverlayStack(uniqueId))
            }
          }

          if (open) {
            dispatch(pushOverlayStack(uniqueId))
          }
        }}
        {...props}
      >
        <RadixDialog.Close style={{ display: 'none' }} ref={closeButtonRef} />
        {!!bottomSheetTrigger && bottomSheetTrigger.length !== 0 && (
          <>{bottomSheetTrigger}</>
        )}
        <RadixDialog.Portal>
          <Overlay />

          <Content
            close={closeDetect}
            transition={closeDetect || backToPosition}
            style={{
              transform: `translateY(${positionY}px)`,
              height: height === 'full' ? FULL_HEIGHT : 'fit-content',
            }}
            fitContent={height === 'fitContent'}
          >
            <BottomSheetBody
              ref={sheetRef}
              onTouchStart={e => {
                if (preventContentDrag) return
                handleTouchStart(e, true)
              }}
              onTouchMove={e => {
                if (preventContentDrag) return
                handleTouchMove(e, true)
              }}
              onTouchEnd={e => {
                if (preventContentDrag) return
                handleTouchEnd(e, true)
              }}
              css={{
                height:
                  height === 'full' ? `calc(${FULL_HEIGHT})` : 'fit-content',
              }}
            >
              <TabsRoot
                defaultValue={DEFAULT_BOTTOM_SHEET_STACK}
                value={bottomSheetStack[bottomSheetStack.length - 1]}
                drilling={bottomSheetStack.length > 1}
                notInitialOpen={!initialOpen}
                onValueChange={value => {
                  pushBottomSheetStack(value)
                }}
              >
                {childrenExceptTrigger}
              </TabsRoot>
            </BottomSheetBody>
          </Content>
        </RadixDialog.Portal>
      </RadixDialog.Root>
    </BottomSheetProvider>
  )
}

export const BottomSheet = Object.assign(BottomSheetMain, {
  Trigger: BottomSheetTrigger,
  Close: BottomSheetClose,
  Title: BottomSheetTitle,
  Description: BottomSheetDescription,
  Content: BottomSheetContent,
  TabsContent: BottomSheetTabsContent,
  TabsTrigger: BottomSheetTabsTrigger,
  TabsBackTrigger: BottomSheetTabsBackTrigger,
})

const animationTimingFunction = 'cubic-bezier(0.300, 1.055, 0.550, 0.965)'
const dialogAnimationDuration = '0.4s'

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.5 },
})

const fadeOut = keyframes({
  '0%': { opacity: 0.5 },
  '100%': { opacity: 0 },
})

const slideUp = keyframes({
  '0%': { transform: `translateY(${FULL_HEIGHT})` },
  '100%': { transform: 'translateY(0)' },
})

const slideDown = keyframes({
  '0%': { transform: 'translateY(0)' },
  '100%': { transform: `translateY(${FULL_HEIGHT})` },
})

const Overlay = styled(RadixDialog.Overlay, {
  inset: 0,
  animation: `${fadeIn} ${dialogAnimationDuration} ${animationTimingFunction}`,
  backgroundColor: '$ref_black',
  opacity: 0.5,
  position: 'fixed',
  willChange: 'opacity',
  zIndex: 10000000,

  '&[data-state="closed"]': {
    animation: `${fadeOut} ${dialogAnimationDuration} ${animationTimingFunction}`,
  },
})

const Content = styled(RadixDialog.Content, {
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  left: 0,
  // ios에서 최하단 부분이 떨어져 보여서 추가
  bottom: -1,
  width: '100%',
  willChange: 'transform',
  zIndex: 10000001,

  '&[data-state="open"]': {
    animation: `${slideUp} ${dialogAnimationDuration} ${animationTimingFunction}`,
  },

  '&[data-state="closed"]': {
    animation: `${slideDown} ${dialogAnimationDuration} ${animationTimingFunction}`,
  },

  variants: {
    transition: {
      true: {
        transition: `transform ${dialogAnimationDuration} ${animationTimingFunction}`,
      },
    },
    close: {
      true: {
        '&[data-state="closed"]': {
          animation: 'unset',
        },
      },
    },
    fitContent: {
      true: {
        '&[data-state="closed"]': {
          animationTimingFunction: 'linear',
        },
      },
    },
  },
})

const BottomSheetHeaderFixed = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: '$system_background_primary',
  zIndex: 10000002,
})

const BottomSheetHeaderContainer = styled('div', {
  position: 'relative',
  width: '100vw',
  height: 48,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 8,
})

const HeaderTitleContainer = styled('div', {
  backgroundColor: '$system_background_primary',
  position: 'absolute',
  top: 0,
  left: 40,
  height: 48,
  width: 'calc(100% - 80px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const BottomSheetBody = styled('div', {
  width: '100%',
  overflow: 'scroll',
  backgroundColor: '$system_background_primary',
  position: 'relative',
})

const BottomSheetTitleWrapper = styled('div', {
  width: '100%',
  marginTop: 16,
  marginBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
})

const BottomSheetDescriptionWrapper = styled('div', {
  width: '100%',
  margin: 0,
  marginTop: 16,
  paddingLeft: 16,
})

const TabsContent = styled(Tabs.Content, {
  width: '100vw',
  overflowX: 'hidden',
  backgroundColor: '$system_background_primary',

  [`& ${BottomSheetDescriptionWrapper} + ${BottomSheetTitleWrapper}`]: {
    marginTop: 8,
  },

  [`& ${BottomSheetTitleWrapper} + ${BottomSheetDescriptionWrapper}`]: {
    marginTop: -8,
    marginBottom: 16,
  },
})

const slideLeftIn = keyframes({
  '0%': { transform: 'translateX(100vw)' },
  '100%': { transform: 'translateX(0)' },
})

const slideRightIn = keyframes({
  '0%': { transform: 'translateX(-35vw)' },
  '100%': { transform: 'translateX(0)' },
})

const slideLeftOut = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(-35vw)' },
})

const slideRightOut = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(100vw)' },
})

const tabAnimationDuration = '0.4s'

const TabsRoot = styled(Tabs.Root, {
  height: '100%',

  variants: {
    notInitialOpen: {
      true: {
        [`& ${TabsContent}`]: {
          '&[data-state="active"]': {
            animation: `${slideRightIn} ${tabAnimationDuration} ${animationTimingFunction}`,
          },

          '&[data-state="inactive"]': {
            position: 'absolute',
            top: 0,
            left: 0,
            animation: `${slideRightOut} ${tabAnimationDuration} ${animationTimingFunction}`,
          },
        },
      },
    },
    drilling: {
      true: {
        [`& ${TabsContent}`]: {
          '&[data-state="inactive"]': {
            position: 'absolute',
            top: 0,
            left: 0,
            animation: `${slideLeftOut} ${tabAnimationDuration} ${animationTimingFunction}`,
          },

          '&[data-state="active"]': {
            animation: `${slideLeftIn} ${tabAnimationDuration} ${animationTimingFunction}`,
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      notInitialOpen: true,
      drilling: true,
      css: {
        [`& ${TabsContent}`]: {
          '&[data-state="inactive"]': {
            position: 'absolute',
            top: 0,
            left: 0,
            animation: `${slideLeftOut} ${tabAnimationDuration} ${animationTimingFunction}`,
          },

          '&[data-state="active"]': {
            animation: `${slideLeftIn} ${tabAnimationDuration} ${animationTimingFunction}`,
          },
        },
      },
    },
  ],
})
