import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export type ChecklistTabType = 'complete' | 'incomplete'

export type ChecklistOptionType = {
  selectedCategoryId: string
  tab: ChecklistTabType
  // 삭제 예정
  viewMoreChecked: boolean
  lastSeenView: string
}

const initialState: ChecklistOptionType = {
  selectedCategoryId: '',
  viewMoreChecked: false,
  lastSeenView: 'category',
  tab: 'incomplete',
}

const checklistOption = createSlice({
  name: 'selectedCategoryId',
  initialState,
  reducers: {
    setSelectedCategoryId: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload
    },
    setViewMoreChecked: (state, action: PayloadAction<boolean>) => {
      state.viewMoreChecked = action.payload
    },
    setLastSeenView: (
      state,
      action: PayloadAction<'category' | 'checklist'>
    ) => {
      state.lastSeenView = action.payload
    },
    setChecklistTab: (state, action: PayloadAction<ChecklistTabType>) => {
      state.tab = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, state => {
      storage.removeItem('root')
    })
  },
})

export const {
  setSelectedCategoryId,
  setViewMoreChecked,
  setLastSeenView,
  setChecklistTab,
} = checklistOption.actions
export default checklistOption.reducer
