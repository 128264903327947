import { Router } from 'next/router'
import { useEffect, useRef, useState } from 'react'

export function useScrollAutoRestore(router: Router) {
  const [prevPath, setPrevPath] = useState<string>('')
  const [prevScrollY, setPrevScrollY] = useState<number>(0)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleBeforeHistoryChange = () => {
      setPrevPath(router.asPath)
      if (ref.current) setPrevScrollY(ref.current?.scrollTop)
    }

    const handleRouterChangeComplete = () => {
      if (
        !prevPath.includes('store/') &&
        prevPath.split('/').length - 1 <= router.asPath.split('/').length - 1
      ) {
        const prevKey = `__next_scroll_${prevPath}`
        sessionStorage.setItem(prevKey, JSON.stringify({ y: prevScrollY }))
      }

      window.history.scrollRestoration = 'manual'
      const key = `__next_scroll_${router.asPath}`
      const _scroll = sessionStorage.getItem(key)
      if (_scroll) {
        const { y } = JSON.parse(_scroll)
        ref.current?.scrollTo(0, y)
        sessionStorage.removeItem(key)
      } else {
        ref.current?.scrollTo(0, 0)
      }
    }

    Router.events.on('beforeHistoryChange', handleBeforeHistoryChange)
    Router.events.on('routeChangeComplete', handleRouterChangeComplete)

    return () => {
      Router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
      Router.events.off('routeChangeComplete', handleRouterChangeComplete)
    }
  }, [prevPath, prevScrollY, router.asPath])

  return {
    ref,
  }
}
