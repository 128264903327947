import React, { forwardRef, useState } from 'react'
import Text from 'components/v2/common/Text'
import { IconButton } from './IconButton'
import { config, styled } from '@higherx/stitches-config'
import { CSS } from '@stitches/react'

export type TextFieldProps = {
  variant: 'line' | 'fill'
  onClear?: () => void
  type?:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'hidden'
    | 'month'
    | 'number'
    | 'tel'
    | 'text'
    | 'time'
    | 'week'
  value?: string | number
  autoFocus?: boolean
  disabled?: boolean
  error?: boolean
  label?: string
  helperText?: string
  suffix?: string
  css?: CSS<typeof config>
  readOnly?: boolean
  max?: string | number
  maxLength?: number
  min?: string | number
  minLength?: number
} & React.HTMLAttributes<HTMLInputElement>

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      variant,
      autoFocus,
      disabled,
      label,
      helperText,
      onClear,
      type = 'text',
      suffix,
      id,
      css,
      readOnly,
      onFocus,
      onBlur,
      onKeyDown,
      error,
      inputMode,
      ...props
    },
    ref
  ) {
    const [test, setTest] = useState<boolean>(
      variant === 'fill' || !!props.value ? true : false
    )
    const [isClearActive, setIsClearActive] = useState<boolean>(false)

    return (
      <InputContainer variant={variant} css={css}>
        <StyledInput
          variant={variant}
          id={id}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          readOnly={readOnly}
          inputMode={inputMode}
          error={error}
          onFocus={e => {
            setIsClearActive(true)

            if (onFocus) onFocus(e)
          }}
          onBlur={e => {
            if (variant === 'line') setTest(!!e.currentTarget.value)

            setTimeout(() => {
              setIsClearActive(false)
            }, 50)

            if (onBlur) onBlur(e)
          }}
          onKeyDown={e => {
            if (
              (inputMode === 'numeric' || inputMode === 'tel') &&
              e.key !== 'Backspace' &&
              Number.isNaN(Number(e.key))
            )
              return e.preventDefault()

            if (onKeyDown) onKeyDown(e)
          }}
          {...props}
          ref={ref}
        />
        {label &&
          (test ? (
            <StyledLabel id='label' htmlFor={id} error={error}>
              {label}
            </StyledLabel>
          ) : props.placeholder ? (
            <StyledLabel id='label' htmlFor={id} error={error}>
              {label}
            </StyledLabel>
          ) : (
            <AnimeLabel id='anime_label' htmlFor={id} error={error}>
              {label}
            </AnimeLabel>
          ))}

        {variant === 'line' && <FocusLine error={error} />}

        {helperText && (
          <HelperTextWrapper>
            <Text
              variant='labelXSmall'
              color={error ? '$system_semantic_negative_middle' : undefined}
            >
              {helperText}
            </Text>
          </HelperTextWrapper>
        )}

        {suffix === undefined && isClearActive && (
          <IconButton
            id='clear_button'
            iconName='FillInterfaceCloseCircle1'
            size='small'
            onClick={onClear}
            color='$system_content_quaternary'
          />
        )}

        {suffix && (
          <Text
            id='suffix'
            variant='titleSmall'
            color='$system_content_secondary'
            weight='medium'
          >
            {suffix}
          </Text>
        )}
      </InputContainer>
    )
  }
)

const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: '100%',
  width: '100%',

  variants: {
    variant: {
      line: {
        '& > #clear_button': {
          opacity: 0,
          position: 'absolute',
          top: 26,
          right: 0,
        },

        '& > #suffix': {
          position: 'absolute',
          top: 33,
          right: 0,
        },
      },
      fill: {
        '& > #clear_button': {
          opacity: 0,
          position: 'absolute',
          top: 33,
          right: 12,
        },
      },
    },
  },
})

const StyledInput = styled('input', {
  border: 'none',
  width: '100%',
  marginTop: 23,
  boxSizing: 'border-box',
  '-webkit-box-sizing': 'border-box',
  '-moz-box-sizing': 'border-box',

  '&:focus + label#anime_label': {
    transform: 'translateY(-33.5px) translateX(-45.5px) scale(0.54545455)',
  },

  '&:focus ~ #clear_button': {
    opacity: 1,
  },
  color: '$system_content_primary',

  '&::placeholder': {
    color: '$system_content_tertiary',
  },

  variants: {
    variant: {
      line: {
        height: 38,
        borderBottom: '1px solid $system_border_tertiary',
        borderRadius: 0,
        padding: 0,
        fontSize: 22,

        '&:focus': {
          paddingRight: 32,
        },

        '&:focus ~ div': {
          transform: 'scale(1)',
        },

        '&:disabled': {
          border: 'none',
          backgroundColor: '$system_background_primary',
          color: '$system_content_quaternary',

          '&::placeholder': {
            color: '$system_content_tertiary',
          },
        },
      },
      fill: {
        height: 52,
        backgroundColor: '$system_background_tertiary',
        borderRadius: 16,
        paddingLeft: 12,
        paddingRight: 16,
        fontSize: 16,

        '&:focus': {
          paddingRight: 44,
          backgroundColor: '$system_semantic_primary_light',
        },
      },
    },
    error: {
      true: {},
    },
  },

  compoundVariants: [
    {
      variant: 'fill',
      error: true,
      css: {
        backgroundColor: '$system_semantic_negative_light',
      },
    },
  ],
})

const AnimeLabel = styled('label', {
  fontSize: 22,
  fontWeight: 400,
  fontFamily: '$pretendard',
  lineHeight: '38px',
  position: 'absolute',
  top: 23,
  left: 0,
  transition: 'transform 0.2s ease',
  width: 200,
  willChange: 'transform',
  color: '$system_content_secondary',

  variants: {
    error: {
      true: {
        color: '$system_semantic_negative_middle',
      },
    },
  },
})

const StyledLabel = styled('label', {
  fontSize: 12,
  fontWeight: 400,
  fontFamily: '$pretendard',
  position: 'absolute',
  top: 0,
  left: 0,
  color: '$system_content_secondary',

  variants: {
    error: {
      true: {
        color: '$system_semantic_negative_middle',
      },
    },
  },
})

const FocusLine = styled('div', {
  position: 'absolute',
  width: '100%',
  top: 60,
  height: 1,
  backgroundColor: '$system_semantic_primary_default',
  transform: 'scale(0)',
  transition: 'transform 0.3s ease-in-out',

  variants: {
    error: {
      true: {
        backgroundColor: '$system_semantic_negative_middle',
      },
    },
  },
})

const HelperTextWrapper = styled('div', {
  marginTop: 8,
})
