import { useEffect } from 'react'
import { BridgeService } from '../core'

export function useHardwareBack(callback: () => void): void {
  useEffect(() => {
    const unsubscribe = BridgeService.addListener('back', () => {
      callback()
    })

    return () => {
      unsubscribe()
    }
  }, [callback])
}
