import { Button } from 'components/v2/designSystem/Button'
import { TextField } from 'components/v2/designSystem/TextField'
import useMyStoreQuery from 'hooks/query/store/useMyStoreQuery'
import usePutStoreMutation from 'hooks/query/store/usePutStoreMutation'

import React, { useRef, useState } from 'react'
import { GsStoreInfoConfirmBottomSheet } from '../GsStoreInfoConfirmBottomSheet'
import { searchBrandStore } from 'lib/api/brand/searchBrandStore'
import { styled } from '@higherx/stitches-config'
import { AxiosError } from 'axios'

const STORE_CODE_MIN_LENGTH = 4
const STORE_CODE_MAX_LENGTH = 5

type StoreCodeEditFormProps = {
  storeId?: number
  onSubmit?: () => void
}

export default function StoreCodeEditForm({
  storeId,
  onSubmit,
}: StoreCodeEditFormProps) {
  const gs25StoreInfo = useRef<{ storeName: string; address: string }>()

  const { data: myStore } = useMyStoreQuery(
    {},
    { enabled: storeId === undefined }
  )

  const [storeCode, setStoreCode] = useState<string>('V')
  const [isDuplication, setIsDuplication] = useState<boolean>(false)
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [isCodeLengthShort, setIsCodeLengthShort] = useState<boolean>(false)

  const [openStoreInfoConfirmBottomSheet, setOpenStoreInfoConfirmBottomSheet] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { mutateAsync: mutatePutStore } = usePutStoreMutation()

  const handleCodeChange = async (e: React.FormEvent<HTMLInputElement>) => {
    if (isInvalid) setIsInvalid(false)
    if (isDuplication) setIsDuplication(false)

    const currentValue = e.currentTarget.value

    setStoreCode(currentValue.toUpperCase())

    if (currentValue.length > 4)
      if (isCodeLengthShort) setIsCodeLengthShort(false)
  }

  const handleCodeConfirm = async () => {
    try {
      setLoading(true)
      const brandStore = await searchBrandStore({
        query: storeCode,
        brand: 'gs25',
      })
      setLoading(false)

      gs25StoreInfo.current = {
        storeName: brandStore[0].store_name,
        address: brandStore[0].address,
      }

      setOpenStoreInfoConfirmBottomSheet(true)
    } catch (error) {
      const axiosError = error as AxiosError
      const errorCode = axiosError.response?.status

      if (errorCode) {
        setIsDuplication(true)
      } else {
        setIsInvalid(true)
      }

      setLoading(false)
    }
  }

  const handleStoreInfoConfirm = async () => {
    const validStoreId =
      storeId === undefined ? Number(myStore?.store_id) : storeId

    setLoading(true)
    await mutatePutStore({
      storeId: validStoreId,
      storeCode: storeCode,
    })
    setLoading(false)
    if (onSubmit) onSubmit()
  }

  return (
    <>
      <TextFieldContainer>
        <TextField
          autoFocus
          inputMode='text'
          autoCapitalize='characters'
          id='storeCode'
          variant='line'
          placeholder={`${STORE_CODE_MIN_LENGTH}자리 이상 영문+숫자 조합`}
          maxLength={5}
          label='점포코드'
          value={storeCode}
          onChange={handleCodeChange}
          error={isDuplication || isInvalid || isCodeLengthShort}
          helperText={
            isDuplication
              ? '이미 등록된 점포 코드예요! 점포 코드는 중복 등록할 수 없어요.'
              : isInvalid
              ? '잘못된 코드입니다. 다시 입력해주세요'
              : isCodeLengthShort
              ? `${STORE_CODE_MIN_LENGTH}자리 이상 영문+숫자를 입력해주세요`
              : undefined
          }
          onBlur={() => {
            if (storeCode.length < STORE_CODE_MIN_LENGTH) {
              setIsCodeLengthShort(true)
            }
          }}
          onClear={() => setStoreCode('')}
        />

        <Button
          variant='fill'
          semantic='primary'
          size='large'
          css={{ marginTop: 12 }}
          onClick={handleCodeConfirm}
          loading={loading}
          disabled={storeCode.length < 4}
        >
          점포코드 확인
        </Button>
      </TextFieldContainer>

      <GsStoreInfoConfirmBottomSheet
        open={openStoreInfoConfirmBottomSheet}
        onOpenChange={setOpenStoreInfoConfirmBottomSheet}
        storeName={gs25StoreInfo.current?.storeName as string}
        address={gs25StoreInfo.current?.address as string}
        code={storeCode}
        onConfirm={handleStoreInfoConfirm}
        confirmLoading={loading}
      />
    </>
  )
}

const TextFieldContainer = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
