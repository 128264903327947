export const DOMAIN_URL_PREFIX = 'wkdk.page.link'
export const SHORT_DOMAIN_URL_PREFIX = 'wkdks.page.link'

export const AOS = {
  minVersion: '2.2.0',
  forceUpdate: true,
  packageName: 'com.clerk.albacheck',
  storeLink: 'market://details?id=com.clerk.albacheck',
}

export const IOS = {
  minVersion: '2.2.0',
  forceUpdate: true,
  bundleId: 'com.clerk.albacheck',
  storeId: '1436707534',
  storeLink: 'itms-apps://itunes.apple.com/app/1436707534',
}
