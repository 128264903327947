import { styled } from '@higherx/stitches-config'

export type BottomCTAProps = {
  primaryButton: React.ReactNode
  secondaryButton?: React.ReactNode
  linear?: boolean
  vertical?: boolean
}

export function BottomCTA({
  primaryButton,
  secondaryButton,
  linear,
  vertical,
}: BottomCTAProps) {
  return (
    <>
      <StyledBottomCTA linear={linear} vertical={vertical}>
        {vertical ? (
          <>
            {primaryButton}
            {secondaryButton}
          </>
        ) : (
          <>
            {secondaryButton}
            {primaryButton}
          </>
        )}
      </StyledBottomCTA>
      <div style={{ height: vertical ? 140 : 80 }} />
    </>
  )
}
const StyledBottomCTA = styled('div', {
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  padding: 16,
  backgroundColor: '$system_background_primary',
  display: 'flex',
  alignItems: 'center',

  button: {
    width: '100%',
  },

  '& > button + button': {
    marginLeft: 8,
  },

  variants: {
    linear: {
      true: {
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 18.23%)',
      },
    },
    vertical: {
      true: {
        flexDirection: 'column',

        '& > button + button': {
          marginLeft: 0,
          marginTop: 12,
        },
      },
    },
  },
})
