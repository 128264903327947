import { BottomSheet } from 'components/v2/designSystem/BottomSheet'
import Text from 'components/v2/common/Text'
import { styled } from '@higherx/stitches-config'
import { BottomCTA } from 'components/v2/designSystem/BottomCTA'
import { Button } from 'components/v2/designSystem/Button'

type GsStoreInfoConfirmBottomSheetProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  storeName: string
  address: string
  code: string
  onConfirm: () => void | Promise<void>
  confirmLoading?: boolean
}

export function GsStoreInfoConfirmBottomSheet({
  open,
  onOpenChange,
  storeName,
  address,
  code,
  onConfirm,
  confirmLoading,
}: GsStoreInfoConfirmBottomSheetProps) {
  return (
    <BottomSheet open={open} onOpenChange={onOpenChange} height='fitContent'>
      <BottomSheet.Content>
        <BottomSheet.Title>
          <Text variant='titleLarge' weight='bold'>
            입력하신 점포 코드를
            <br />
            등록할까요?
          </Text>
        </BottomSheet.Title>

        <GsStoreInfoBox>
          <GsStoreInfoLow>
            <Text variant='labelSmall' color='$system_content_secondary'>
              매장명
            </Text>
            <Text variant='labelLarge' weight='medium'>
              {storeName}
            </Text>
          </GsStoreInfoLow>
          <GsStoreInfoLow>
            <Text variant='labelSmall' color='$system_content_secondary'>
              점포코드
            </Text>
            <Text variant='labelLarge' weight='medium'>
              {code}
            </Text>
          </GsStoreInfoLow>
          <GsStoreInfoLow>
            <Text variant='labelSmall' color='$system_content_secondary'>
              주소
            </Text>
            <Text variant='labelLarge' weight='medium'>
              {address}
            </Text>
          </GsStoreInfoLow>
        </GsStoreInfoBox>

        <BottomCTA
          primaryButton={
            <Button
              variant='fill'
              semantic='primary'
              size='large'
              onClick={onConfirm}
              loading={confirmLoading}
            >
              등록
            </Button>
          }
          secondaryButton={
            <BottomSheet.Close asChild>
              <Button variant='line' semantic='neutral' size='large'>
                돌아가기
              </Button>
            </BottomSheet.Close>
          }
        />
      </BottomSheet.Content>
    </BottomSheet>
  )
}

const GsStoreInfoBox = styled('div', {
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$system_semantic_informative_light',
  margin: 16,
  marginTop: 32,
})

const GsStoreInfoLow = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: 16,

  '& > * + *': {
    marginTop: 2,
  },
})
