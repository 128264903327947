import { exitApp } from './actions/exitApp'
import { getAppVersion } from './actions/getAppVersion'
import { getBarcode } from './actions/getBarcode'
import { kakaoMessage } from './actions/kakaoMessage'
import { smsMessage } from './actions/smsMessage'
import { loadData } from './actions/loadData'
import { saveData } from './actions/saveData'
import { removeData } from './actions/removeData'
import { getPushToken } from './actions/getPushToken'
import { openCheckCamera } from './actions/openCheckCamera'
import { openInAppBrowser } from './actions/openInAppBrowser'
import { openPASS } from './actions/openPASS'
import { openURL } from './actions/openURL'
import { showCRM } from './actions/showCRM'
import { showDebugView } from './actions/showDebugView'
import { getPlatform } from './actions/getPlatform'
import { getGeoLocation } from './actions/getGeoLocation'
import { notSupportedAction } from './virtual-actions/notSupportedAction'
import { virtualLoadData } from './virtual-actions/loadData'
import { virtualSaveData } from './virtual-actions/saveData'
import { virtualRemoveData } from './virtual-actions/removeData'
import { virtualOpenInAppBrowser } from './virtual-actions/openInAppBrowser'
import { virtualOpenURL } from './virtual-actions/openURL'
import { virtualGetPushToken } from './virtual-actions/getPushToken'
import { platformSelect } from './utils/platformSelect'
import { getCurrentWifi } from './actions/getCurrentWifi'
import { hapticFeedback } from './actions/hapticFeedback'
import { openCallbackView } from './actions/openCallbackView'
import { getCodePushVersion } from './actions/getCodePushVersion'
import { getFullVersion } from './actions/getFullVersion'
import { openPdf } from './actions/openPdf'
import { openGallery } from './actions/openGallery'
import { openCamera } from './actions/openCamera'
import { openFileDialog } from './actions/openFileDialog'

export * from './HigherXAppProtocol'

export * from './actions/types'
export * from './core/types'
export * from './useHXAP'

export const mobileHXAP = {
  exitApp,
  getAppVersion,
  getBarcode,
  kakaoMessage,
  smsMessage,
  loadData,
  saveData,
  removeData,
  getPushToken,
  openCheckCamera,
  openInAppBrowser,
  openPASS,
  openURL,
  showCRM,
  showDebugView,
  getPlatform,
  getGeoLocation,
  getCurrentWifi,
  hapticFeedback,
  openCallbackView,
  getCodePushVersion,
  getFullVersion,
  openPdf,
  openGallery,
  openCamera,
  openFileDialog,
}

//지원하지 않는 환경에서 사용되는 actions
const virtualHXAP: typeof mobileHXAP = {
  exitApp: notSupportedAction,
  getAppVersion: notSupportedAction,
  getBarcode: notSupportedAction,
  kakaoMessage: notSupportedAction,
  smsMessage: notSupportedAction,
  loadData: virtualLoadData,
  saveData: virtualSaveData,
  removeData: virtualRemoveData,
  getPushToken: virtualGetPushToken,
  openCheckCamera: notSupportedAction,
  openInAppBrowser: virtualOpenInAppBrowser,
  openPASS: notSupportedAction,
  openURL: virtualOpenURL,
  showCRM: notSupportedAction,
  showDebugView: notSupportedAction,
  getPlatform,
  getGeoLocation: notSupportedAction,
  getCurrentWifi: notSupportedAction,
  hapticFeedback: notSupportedAction,
  openCallbackView: notSupportedAction,
  getCodePushVersion: notSupportedAction,
  getFullVersion: notSupportedAction,
  openPdf: notSupportedAction,
  openGallery: notSupportedAction,
  openCamera: notSupportedAction,
  openFileDialog: notSupportedAction,
}

export const HXAP = platformSelect({
  android: mobileHXAP,
  ios: mobileHXAP,
  unknown: virtualHXAP,
})
