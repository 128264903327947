import client from '../client'

export async function searchBrandStore(params: SearchBrandStoreParams) {
  const response = await client.get<
    {
      store_id: number | null
      store_name: string
      address: string
      address2: string | null
      tel: string | null
      store_type: string | null
      crn: string
      lat: number | null
      lng: number | null
      date_created: string | null
      brand_id: number
      brand_store_id: null
      brand_name: string | null
      brand_code: string | null
      shinhan_code: string | null
      custom_category: null
      is_temp_store: boolean
    }[]
  >('/v1/store/find/brand', {
    params,
  })
  return response.data
}

export type SearchBrandStoreParams = {
  query: string
  brand: string
  code?: string
  page?: string
}
